import { composeWithDevTools } from "@redux-devtools/extension"
import { routerMiddleware } from "connected-react-router"
import { applyMiddleware, legacy_createStore as createStore } from "redux"
import thunkMiddleware from "redux-thunk"

// import monitorReducersEnhancer from './enhancers/monitorReducers'
// import loggerMiddleware from './middleware/logger'
// import rootReducer from 'reducers'
import rootReducer from "reduxModules/index"

import {notificationMiddleware} from "./middlewares/notifications"

export default function configureAppStore(history, preloadedState) {
  const reducers = rootReducer(history)
  const middlewares = [routerMiddleware(history), thunkMiddleware, notificationMiddleware]
  const middlewareEnhancer = applyMiddleware(...middlewares)
  const enhancers = [middlewareEnhancer]
  const composeEnhancers = composeWithDevTools({
    trace: true,
    traceLimit: 25,
  })
  const store = createStore(reducers, preloadedState, composeEnhancers(...enhancers))

  return store
}
