/* === IMPORTS === */
import {createReducer} from "@reduxjs/toolkit"

import {CLEAN_SPACE} from "actions/auth"
import {makeActions} from "reduxModules/utiliducks"
import {makeLib} from "utils/misc"
import {addMessage, MESSAGE_TYPE_ERROR,} from "utils/notifications"
import {
  doLinkRequest,
  filterDummyFiles,
  getFilesRequest,
  getFilterQueryParams,
  getSortQuery
} from "utils/packages/objects"
import {getTableSort} from "utils/storage"

/* == ACTIONS === */
const actionList = [
  "setFilesAction",
  "setFilesPagingAction",
]
const {
  setFilesAction,
  setFilesPagingAction,
} = makeActions("marketplaceObjects", actionList)

/* === INITIAL STATE === */
const initialState = {
  files: [],
  filesLib: {},
  filesPaging: { previous_cursor: "", next_cursor: "" },
}

/* === Reducer === */
export default createReducer(initialState, {
  [setFilesAction]: (state, { payload: { files }}={}) => ({
    ...state,
    files,
    filesLib: makeLib({data: files}),
  }),
  [setFilesPagingAction]: (state, { payload: { paging } }) => ({
    ...state,
    filesPaging: paging
  }),
  [CLEAN_SPACE]: () => initialState
})

/* === DISPATCHERS === */
export const getFiles = (params) => {
  return async (dispatch) => {
    try {
      const response = await getFilesRequest(params)
      const { data: files, paging } = response
      const filteredDummyFiles = filterDummyFiles(files)
      dispatch(setFilesAction({ files: filteredDummyFiles }))
      if (paging) dispatch(setFilesPagingAction({ paging }))
      return files
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        text: "Files could not be retrieved",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const getNextFiles = () => {
  return async (dispatch, getState) => {
    const {
      files: {
        files,
        filesPaging: oldPaging
      }
    } = getState()
    const { data: nextFiles, paging } = await getFilesRequest({next_cursor: oldPaging.next_cursor})
    const filteredDummyFiles = filterDummyFiles(nextFiles)
    dispatch(setFilesAction({ files: [...files, ...filteredDummyFiles]}))
    if (paging) dispatch(setFilesPagingAction({paging}))
  }
}

export const getQueryParams = (tableName, tableColumns) => {
  return (dispatch, getState) => {

    const {
      authentication: {
        userInfo: {
          username
        }
      }
    } = getState()

    const filterQueryParams = getFilterQueryParams(tableName, tableColumns, username)
    const sort = getSortQuery(getTableSort(`${tableName}`, username) || {})
    return {...filterQueryParams, ...sort}
  }
}

export const getFileData = (id) => {
  return async () => {
    return await doLinkRequest(id)
  }
}
