import {StatusCodes} from "better-status-codes/dist/codes"

import {get, patch} from "./api"
import {getSpace} from "./storage"

export async function getNotificationsRequest(params) {
  const space = getSpace()
  if (!space) return {}

  const response = await get(`/spaces/${space}/notifications`, params, { rootRequest: true })
  return response.status === StatusCodes.OK ? response.data : {}
}

export async function updateNotificationRequest(id, body) {
  const response = await patch(`/notifications/${id}`, body)
  const {
    status,
    data
  } = response
  if (status === StatusCodes.OK) return data
  throw new Error(`Request failed with status ${response.status} - ${response.data}`)
}

export async function updateNotificationsRequest(body) {
  const response = await patch("/notifications", body)
  const {
    status,
    data
  } = response
  if (status === StatusCodes.OK) return data
  throw new Error(`Request failed with status ${response.status} - ${response.data}`)
}