import {StatusCodes} from "better-status-codes/dist/codes"

import {get} from "utils/api"

export async function getIntroVideoRequest() {
  const response = await get("/static/home/intro-video", null, { rootRequest: true })
  return response.status === StatusCodes.OK ? response.data : {}
}

export async function getMediaWebinarsRequest() {
  const response = await get("/static/home/media-webinars", null, { rootRequest: true })
  return response.status === StatusCodes.OK ? response.data : {}
}

export async function getUseCasesRequest() {
  const response = await get("/static/home/iot-use-cases", null, { rootRequest: true })
  return response.status === StatusCodes.OK ? response.data : {}
}

export async function getTutorialsRequest() {
  const response = await get("/static/home/tutorials", null, { rootRequest: true })
  return response.status === StatusCodes.OK ? response.data : {}
}

export async function getReleasesNotesRequest() {
  const response = await get("/static/home/releases-notes", null, { rootRequest: true })
  return response.status === StatusCodes.OK ? response.data : {}
}