import { createReducer } from "@reduxjs/toolkit"

import { CLEAN_SPACE } from "actions/auth"
import { createAssetConfigurationRequest, deleteAssetConfigurationRequest, getAssetConfigurationsRequest, updateAssetConfigurationRequest } from "utils/assetConfigurations"
import { makeLib } from "utils/misc"
import { addMessage, GLOBAL_NOTIFICATIONS, MESSAGE_TYPE_ERROR, MESSAGE_TYPE_SUCCESS, MESSAGE_TYPE_WARNING } from "utils/notifications"

import { makeActions } from "./utiliducks"

const actionList = [
  "addAssetConfigurationAction",
  "removeAssetConfigurationsAction",
  "setPagingAction",
  "setAssetConfigurationsAction",
  "updateAssetConfigurationAction"
]

const {
  addAssetConfigurationAction,
  removeAssetConfigurationsAction,
  setPagingAction,
  setAssetConfigurationsAction,
  updateAssetConfigurationAction
} = makeActions("assetConfigurations", actionList)

const initialState = {
  assetConfigurations: [],
  assetConfigurationsLib: {},
  paging: { previous_cursor: "", next_cursor: "" }
}

export default createReducer(initialState, {
  [addAssetConfigurationAction]: (state, { payload: { assetConfiguration } }) => {
    if (state.assetConfigurationsLib[assetConfiguration.id]) {
      state.assetConfigurations = state.assetConfigurations.map(a => a.id === assetConfiguration.id ? assetConfiguration : a)
    } else {
      state.assetConfigurations.push(assetConfiguration)
    }

    state.assetConfigurationsLib[assetConfiguration.id] = assetConfiguration
  },
  [removeAssetConfigurationsAction]: (state, { payload: { ids }}) => {
    state.assetConfigurations = state.assetConfigurations.filter(assetConfiguration => !ids.includes(assetConfiguration.id))
    state.assetConfigurationsLib = makeLib({ data: state.assetConfigurations, key: "id" })
  },
  [setAssetConfigurationsAction]: (state, { payload: { assetConfigurations } }) => {
    state.assetConfigurations = assetConfigurations
    state.assetConfigurationsLib = makeLib({ data: assetConfigurations, key: "id" })
  },
  [updateAssetConfigurationAction]: (state, { payload: { assetConfiguration } }) => {
    state.assetConfigurations = state.assetConfigurations.map(a => a.id === assetConfiguration.id ? assetConfiguration : a)
    state.assetConfigurationsLib[assetConfiguration.id] = assetConfiguration
  },
  [setPagingAction]: (state, { payload: { paging } }) => {
    state.paging = paging
  },
  [CLEAN_SPACE]: () => initialState
})

export const getAssetConfiguration = (id) => {
  return async dispatch => {
    try {
      const assetConfiguration = await getAssetConfigurationsRequest(id)
      dispatch(addAssetConfigurationAction({ assetConfiguration }))

      return assetConfiguration
    } catch (error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Asset Configuration could not be retrieved",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR,
      })
    }
  }
}

export const getAssetConfigurations = ({ next=false, tableFilters = {} }={}) => {
  return async (dispatch, getState) => {
    try {
      const {assetConfigurations} = getState()?.assetConfigurations || {}
      const {next_cursor} = getState()?.assetConfigurations?.paging || {}
 

      const shouldGetNext = next && next_cursor
      if(next && !shouldGetNext) return

      const params = shouldGetNext ? { next_cursor } : null
      const { data, paging } = await getAssetConfigurationsRequest({...params, ...tableFilters})
      const assetConfigurationsList = shouldGetNext ? [ ...assetConfigurations, ...data ] : data
      dispatch(setAssetConfigurationsAction({ assetConfigurations: assetConfigurationsList }))
      dispatch(setPagingAction({ paging }))
      return assetConfigurationsList
    }
    catch (error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Asset Configurations could not be retrieved",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR,
      })
    }
  }
}

export const createAssetConfiguration = (body) => {
  return async dispatch => {
    try {
      const assetConfiguration = await createAssetConfigurationRequest(body)
      dispatch(addAssetConfigurationAction({ assetConfiguration }))
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Asset Configuration was created successfully",
        type: MESSAGE_TYPE_SUCCESS,
      })
      return assetConfiguration
    }
    catch (error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Asset Configuration could not be created",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR,
      })
    }
  }
}

export const deleteAssetConfigurations = ids => {
  return async dispatch => {
    const deletedAssetConfigurations = await Promise.allSettled(ids.map(id => deleteAssetConfigurationRequest(id)))
    const allDeleted = deletedAssetConfigurations.filter(promise => promise.status === "fulfilled").map(promise => promise.value)

    if (allDeleted.length === ids.length) {
      dispatch(removeAssetConfigurationsAction({ ids }))
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Delete Complete",
        subtext: `${ids.length === 1 ? "Asset Configuration was" : `${ids.length} Asset Configurations were`} deleted successfully`,
        type: MESSAGE_TYPE_SUCCESS
      })
    }

    if (allDeleted.length > 0 && allDeleted.length < ids.length) {
      dispatch(removeAssetConfigurationsAction({ ids: allDeleted }))
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Delete partially complete",
        subtext: `Only ${ids.length === 1 ? "Asset Configuration was" : `${ids.length} Asset Configurations were`} deleted successfully out of ${ids.length}`,
        type: MESSAGE_TYPE_WARNING
      })
    }

    if (allDeleted.length === 0) {
      console.error(deletedAssetConfigurations.map(promise => promise.reason))
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Delete Failed",
        subtext: String(deletedAssetConfigurations[0].reason),
        type: MESSAGE_TYPE_ERROR,
      })
    }

    return true
  }
}

export const updateAssetConfiguration = (assetConfiguration) => {
  return async dispatch => {
    try {
      const newAssetConfiguration = await updateAssetConfigurationRequest(assetConfiguration)
      dispatch(updateAssetConfigurationAction({ assetConfiguration: newAssetConfiguration }))
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Asset configuration was updated successfully",
        type: MESSAGE_TYPE_SUCCESS,
      })
      return newAssetConfiguration
    }
    catch (error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Asset configuration could not be updated",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR,
      })
    }
  }
}