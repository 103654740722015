export const colors = {
  white:      "#FFFFFF",
  black:      "#000000",
  overlay:    "rgba(5, 19, 22, 0.8)",

  shade1:     "#a7adaf", // slightly blue dark grey
  shade2:     "#d4d9db", // slightly blue medium grey
  shade3:     "#f1f3f4", // slightly blue light grey

  text1:      "#5f6368", // slightly blue dark grey text/icon
  text2:      "#80868b", // slightly blue medium grey text/icon
  text3:      "#b8b8b8", // slightly blue light grey text/icon

  nav1:       "#005776", // primary blue
  nav2:       "#00465e", // dark primary blue

  primary1:   "rgb(250,70,22)", // primary red-orange
  primary2:   "rgba(250,70,22,0.4)", // primary red-orange w/ alpha
  primary3:   "rgb(253,181,162)", // lighter primary red-orange
  primary4:   "rgb(200, 51, 5)", // darker primary red-orange

  secondary:  "rgb(45,204,211)", // secondary teal

  danger1:    "rgb(255,198,0)", // danger yellow
  danger2:    "rgb(255,232,153)", // danger yellow
  success:    "rgb(71,162,63)", // success green

  tertiary1:   "#a2aaad", // slightly blue grey

  charcoal:       "#4D4D4D",
  gray:           "#A2AAAD",
  lightGray1:     "#EBEBEB",
  lightGray2:     "#F4F4F4",
  deepBlue:       "#005776",
  deepBlueShade:  "#02455E",
  deepBlueTint:   "#408199",
  skyBlue:        "#2DCCD3",
  skyBlueShade:   "#32A6AC",
  skyBlueTint1:   "#EAF9FA",
  skyBlueTint2:   "#D9F3F4",
  redOrange:      "#FA4616",
  darkRedOrange:  "#913D26",
  orangeShadow1:   "#FA582E",
  orangeShadow2:   "#FB6C48",
  yellow:         "#FFC600",
  yellowShade1:   "#BD9300",
  yellowShade2:   "#695200",
  yellowShade3:   "#FFF3C9",
  green:          "#47A23F",
  greenShade1:    "#36792F",
  greenShade2:    "#275722",
  orange:         "#FFA300",
  purple:         "#642667",
  seafoam:        "#A7E6D7",

  modalBackdrop:  "rgba(47, 47, 47, .8)",

  // new color naming
  "blue-01-550": "#6197AB",
  "blue-01-600": "#4E8AA0",
  "blue-01-825": "#005776"
}

// util for converting CSS rgb to a value string for use in WC variables
const convertColor = cssStr => {
  // variable a is defined only as a placeholder
  // eslint-disable-next-line
  const [a, rgb] = cssStr.match(/\((\d+\,\s*\d+\,\s*\d+(\,\s*\d+)?)\)/) || []

  return rgb
}

export const unityVars = {
  /* Input text color */
  "--black-color": colors.black,

  /* Default background color, Font Color */
  "--white-color": colors.white,

  /* dark grey, text default */
  "--dark-gray-color": colors.charcoal,

  /* normal gray, border default, disabled text */
  "--gray-color": colors.gray,

  /* light gray 1, disabled content */
  "--light-gray-1-color": colors.lightGray1,

  /* light gray 2, disabled content */
  "--light-gray-2-color": colors.lightGray2,

  /* default brand color, switches, radios, checkboxes, focus */
  "--primary-color": colors.skyBlue,

  /* darked primary color */
  "--primary-shade-color": colors.skyBlueShade,

  /* faint primary highlight */
  "--primary-tint-1-color": colors.skyBlueTint1,

  /* primary highlight */
  "--primary-tint-2-color": colors.skyBlueTint2,

  /* secondary brand color, buttons, tabs, links */
  "--secondary-color": colors.deepBlue,

  /* muted secondary color, clicked button */
  "--secondary-tint-color": colors.deepBlueTint,

  /* utility color, error, alert, attention grabbing, present/past-tense */
  "--tertiary-1-color": colors.redOrange,

  /* darker tertiary 1, pressed alert button */
  "--tertiary-1-shade-color": colors.darkRedOrange,

  /* utility color, warning, alert, future/present-tense */
  "--tertiary-2-color": colors.yellow,

  /* utility color, success, notification */
  "--tertiary-3-color": colors.green,

  /* Old Colors */
  "--primary-brand-rgb": convertColor(colors.primary1),
  "--secondary-brand-rgb": convertColor(colors.secondary),
  "--selection-opacity": 0.5,
  "--primary-brand-rgb-light": convertColor(colors.primary3),
  "--primary-brand-rgb-dark": convertColor(colors.primary4),

  /* Colors*/
  "--primary-brand-color": "rgb(var(--primary-brand-rgb))",
  "--secondary-brand-color": "rgb(var(--secondary-brand-rgb))",
  // '--primary-brand-color-light': 'rgb(var(--primary-brand-rgb-light))',
  "--primary-brand-color-dark": "rgb(var(--primary-brand-rgb-dark))",
  "--success-color": colors.success,
  "--danger-color": colors.danger1,
  "--danger-lite-color": colors.danger2,
  "--background-color": "var(--white-color)",
  // '--global-nav-background-color': colors.white,
  // '--global-nav-expanded-color': colors.white,
  // '--global-nav-border-color': colors.tertiary,
  // '--global-nav-text-color': colors.white,
  "--black-text-color": colors.black,
  "--dark-grey-text-color": colors.text1,
  "--medium-grey-text-color": colors.text2,
  "--light-grey-text-color": colors.text3,
  "--dark-grey-background-color": colors.shade1,
  "--medium-grey-background-color": colors.shade2,
  "--light-grey-background-color": colors.shade3,

  /* Fonts */
  "--font-family": "Noto",

  "--header1-font-size": "18px",
  "--header1-font-weight": "400",

  "--header2-font-size": "16px",
  "--header2-font-weight": "400",

  "--header2-selected-font-size": "16px",
  "--header2-selected-font-weight": "600",

  "--paragraph-font-size": "12px",
  "--paragraph-font-weight": "400",

  /* this font size should likely change */
  "--small-text-size": "10px",
  "--small-text-weight": "400",

  "--small-text-selected-size": "10px",
  "--small-text-selected-weight": "600",
  "--unity-button-height": "27px",

  "--padding-size-sm": "8px",

  // links
  "--enabled-link-color": colors["--blue-01-825"],
  "--active-link-color": colors["blue-01-550"],
  "--visited-link-color": colors["blue-01-600"]
}
