import moment from "moment"

import {get} from "utils/api"
import {
  addMessage,
  MESSAGE_TYPE_ERROR
} from "utils/notifications"
import { getTableFilters } from "utils/storage"

// REQUESTS

export async function getFilesRequest(params) {
  const { data } = await get("/marketplace/objects", params, { rootRequest: true })
  return data
}

export function isFolder(record) {
  return !record.name
}

export function filterDummyFiles(files) {
  return files.filter(f => f.name !== "dummy.dmy")
}

export async function downloadFile (data, fileName) {
  try {
    const blobUrl = URL.createObjectURL(data)
    const link = document.createElement("a")
    link.href = blobUrl
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  catch(error) {
    console.error(`${error.name}: ${error.message}`)
    addMessage({
      text: "File could not be downloaded",
      subtext: error.message,
      type: MESSAGE_TYPE_ERROR
    })
  }
}

export const getFilterQueryParams = (tableName, columns, username) => {
  const columnFilters = getTableFilters(tableName, username) || {}
  return getColumnFilterQuery(columnFilters, columns)
}

export function getColumnFilterQuery(columnFilters={}) {
  let filters = {}
  for(let colKey in columnFilters) {
    const {[colKey]: colFilters=[]} = columnFilters

    //skip if column has no filters
    if (colFilters.length === 0) continue

    if (colKey === "name" || colKey === "path") {
      if (colFilters[0]?.value) {
        filters[colKey] = colFilters[0].value
      }
    } else if (colKey === "content_type") {
      if (colFilters[0]?.value) {
        if (!colFilters[0].value.startsWith(".")) {
          // If the string doesn't start with a dot, prepend it
          filters[colKey] = "." + colFilters[0].value
        } else filters[colKey] = colFilters[0].value
      }
    } else if (colKey === "created_at") {
      if (colFilters[0]?.value) {
        //Allow various filters at the same time
        filters[colKey] = colFilters.map(f => {
          let filterValue = moment(f.value).format("YYYY-MM-DDTHH:mm:ss.SSSSSS[Z]")
          //We check if the user inputs exactly the date with the API format to avoid the zerofill moment format in the last 3 milisecs
          if(f.value.length === 27) {
            filterValue = f.value
          }
          switch (f.operator) {
          case "bt":
            return "gt:" + filterValue
          case "st":
            return "lt:" + filterValue
          case "bte":
            return "gte:" + filterValue
          case "ste":
            return "lte:" + filterValue
          default:
            return "eq:" + filterValue
          }
        })
      }
    }
  }
  return filters
}

export const hasColumnFilters = (tableName, username) => {
  const filters = getTableFilters(tableName, username)
  return Object.keys(filters).some(f => !!filters[f].length)
}

export const getSortQuery = sort => {
  const { direction, column } = sort
  let query
  switch (direction) {
  case "Ascending":
    query = `+${column}`
    break
  case "Descending":
    query = `-${column}`
    break
  default:
    break
  }
  return query? { sort: query } : {}
}

export async function doLinkRequest(id) {
  try {
    const { data } = await get(`/marketplace/objects/${id}/download`, "", { rootRequest: true, responseType: "blob" })
    return data
  } catch (error) {
    if (error.status >= 400) {
      const response = await error.message.text()
      const contentType = error.message.type || ""
      const parsedResponse = contentType.includes("application/json")
        ? JSON.parse(response).message
        : response

      addMessage({
        text: "File could not be downloaded",
        subtext: parsedResponse,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}
