import { UnityCheckbox, UnityTypography } from "libs/UnityCoreReact"
import mqttPattern from "mqtt-pattern"
import React from "react"
// import "@bit/smartworks.unity.unity-core/unity-icon"

import { del, get, post, put } from "utils/api"
import customPolicy from "utils/customPolicy.json"
import { getTopicParams } from "utils/mqtt"

/** REQUESTS */

export async function getPoliciesRequest(params={}) {
  const { data } = await get("/policies", params)
  return data
}

export async function createPolicyRequest(policy) {
  const { data } = await post("/policies", policy)
  return data
}

export async function updatePolicyRequest(id, policy) {
  const { data } = await put(`/policies/${id}`, policy)
  return data
}

export async function updatePoliciesRequest(policies) {
  const { data } = await put("/policies", policies)
  return data
}

export async function deletePolicyRequest(id) {
  return await del(`/policies/${id}`)
}

export async function deletePoliciesRequest(params) {
  const { data } = await del("/policies", params)
  return data
}


/** TABLE CONTENT */

export const getCrudCheckbox = ({node, action, onChangeCallback, props={}, disabled=false, style={}}) => {
  const { action: value } = node
  const valueList = value? (value==="#"? ["create", "read", "update", "delete"] : value.split(",")) : []
  const checked = valueList.includes(action)
  return (
    <UnityCheckbox
      checked={checked}
      disabled={disabled || (checked && valueList.length === 1)} // prevent deleting all actions
      style={{margin: "auto", ...style}}
      onChange={ event => {
        const newValue = event.target.checked? [...valueList, action] : valueList.filter(v => v !== action)
        const newAction = { action: (newValue.length === 4)? "#" : newValue.join(",") }
        if(typeof onChangeCallback === "function") onChangeCallback(node, newAction, props)
      }}
    />
  )
}

export const getPolicyEffect = value => {
  let iconColor, iconName
  switch (value) {
  case "allow":
    iconColor = "var(--tertiary-3-color, var(--default-tertiary-3-color))"
    iconName = "unity:circle_check"
    break
  case "deny":
    iconColor = "var(--tertiary-1-color, var(--default-tertiary-1-color))"
    iconName = "unity:circle_minus"
    break
  default:
    break
  }
  return (
    <div style={{display: "flex", justifyContent: "space-bewteen"}}>
      <unity-icon style={{color: iconColor, paddingRight: "5px"}} icon={iconName}></unity-icon>
      <UnityTypography>{value}</UnityTypography>
    </div>
  )
}

export const formatName = (name, type, value) => {
  name = toTitleCase(name)
  if(name === "+") name = "entity"
  if (value === "+") return `any ${name}`
  if (value === "#") return `any ${name}`
  else if (type === "ulid" || type === "number") return `${name} ${value}`
  else return `the ${value} ${name}`
}

export const getPolicyGroup = (resource="") => {
  // idenfity on which table belongs the resource
  const resourceFirstLevel = resource.split("/")[0]
  switch (resourceFirstLevel) {
  case "#":
    return "all"
  case "categories":
  case "models":
  case "things":
  case "query":
    return "anything-db"
  case "sources":
    return "data"
  case "labels":
    return "labels"
  case "triggers":
  case "functions":
  case "function-templates":
  case "objects":
    return "functions"
  case "mqtt-credentials":
  case "hfd":
    return "mqtt"
  case "ecpimages":
  case "edge-apps":
  case "edge-distributions":
  case "edge-rollouts":
  case "clusters":
  case "resources":
    return "edge-ops"
  case "apps":
  case "users":
  case "invitations":
  case "roles":
  case "policies":
  case "variables":
    return "authorization"
  case "panopticon":
    return "panopticon"
  default:
    return "unknown"
  }
}

export const getResourceLabel = resource => {
  const { template="", parsedName="", data: { fields=[] }={} } = getResourceData(resource) ?? {}
  const match = getTopicParams(template, resource)
  if(match) {
    let label = parsedName
    fields.forEach(({ id, name, type }) => label = label.replace(`{${id}}`, formatName(name, type, match[id])))
    label = capitalizeFirstLetter(label)
    return label
  }
  else return resource
}

export const getResourceData = resource => {
  const policyGroup = getPolicyGroup(resource)
  return customPolicy[policyGroup]?.resources.find(r => mqttPattern.matches(r.id, resource))
}

export const getResourceFieldsValues = resource => {
  const { template="" } = getResourceData(resource) ?? {}
  return getTopicParams(template, resource)
}

export const getPolicyIdentifier = (policy={}) => {
  const { resource, effect, action } = policy
  return `${resource}_${effect}_${action.split(",").map(a => a[0]).join("")}`
}

/**
 * Transform the first letter of the string into uppercase
 */
const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1)

/**
 * Transform the sentence to Title Case.
 * Capitalizes the first letter of all the words in a sentence.
 */
const toTitleCase = string => string.split(" ").map(s => capitalizeFirstLetter(s)).join(" ")
