import { Select } from "antd"
import noop from "lodash/noop"
import React from "react"
import styled from "styled-components"

import { CloseIcon, SortDownIcon } from "assets/icons"
import UnityIcon from "components/unity/UnityIcon"

import parseOptions from "./parseOptions"
import UnityDropdownCheckbox from "./UnityDropdownCheckbox"
import UnityDropdownHelperText from "./UnityDropdownHelperText"
import UnityDropdownLabel from "./UnityDropdownLabel"
import UnityDropdownOptionsList from "./UnityDropdownOptionsList"

const filterOption = (input, option) =>
  (option?.label?.props?.children[1] ?? "")
    .toLowerCase()
    .includes(input.toLowerCase())

const StyledDropdown = styled(Select)`
  &&& {
    --dropdown-line-height-internal: var(--dropdown-line-height, 24px);
    --dropdown-border-radius-internal: var(--dropdown-border-radius, 2px);
    --dropdown-width-internal: var(--dropdown-width, 100%);
    --dropdown-color-internal: var(--dropdown-color, black);

    width: var(--dropdown-width-internal);

    &:hover .ant-select-selector {
      border-color: #4d4d4d !important;
    }

    .ant-select-selector {
      transition-duration: 0s !important;
      border-radius: var(--dropdown-border-radius-internal);
      border-color: #a2aaad;
      padding-left: 4px !important;
      height: var(--dropdown-line-height-internal);

      &::after {
        line-height: var(--dropdown-line-height-internal);
        margin: 0;
      }

      &,
      &:hover,
      & .ant-select-selection-placeholder,
      & .ant-select-selection-item {
        color: var(--dropdown-color-internal);
        line-height: var(--dropdown-line-height-internal);
        background-color: transparent;
        padding: 0;
        ${UnityDropdownCheckbox} {
          display: none;
        }
      }

      & .ant-select-selection-overflow {
        display: inline;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 22px;

        & .ant-select-selection-overflow-item-suffix {
          display: none !important;
        }

        & .ant-select-selection-overflow-item {
          display: inline;
        }
      }
    }

    &.ant-select-open .ant-select-selector {
      border-color: var(--primary-color) !important;
    }

    /* Disabled states for the selector */
    &.ant-select-disabled .ant-select-selector {
      border-color: #a2aaad;
      color: rgba(0, 0, 0, 0.5);
      background-color: #f4f4f4;

      & .ant-select-selection-placeholder {
        color: rgba(0, 0, 0, 0.5);
      }
    }

    /* Focused states for selector */
    &.ant-select-focused .ant-select-selector {
      border-color: var(--primary-color) !important;
    }

    .ant-select-clear {
      width: auto;
      height: auto;
    }
  }
`

/**
 * @typedef {{
 *  '--dropdown-color'?: string;
 *  '--dropdown-line-height'?: string;
 *  '--dropdown-width'?: string;
 *  '--dropdown-border-radius'?: string;
 *  '--dropdown-search-input-padding'?: string; // TODO: This is mostly used in MENU type, don't forget to add it
 *  '--dropdown-options-list-max-height'?: string;
 *  '--dropdown-highlighted-option-color'?: string;
 *  '--dropdown-highlighted-option-hover-color'?: string;
 * }} DropdownSelectCustomStyle
 */

/**
 * @typedef {Object} DropdownSelectProps
 * @property {string} [label] - The label for the input
 * @property {string} [placeholder] - The placeholder for the input
 * @property {boolean} [disabled] - If the input is disabled
 * @property {boolean} [allowClear] - If the input allows clearing the value
 * @property {React.ReactNode} [bottomContent] - The content to be displayed below list of options
 * @property {string} [name] - The name of the input
 * @property {'single-select'|'multi-select'} [inputType] - The type of the input
 * @property {"label"|"search"|"button-borderless"} [boxType] - The type of the box
 * @property {React.CSSProperties & DropdownSelectCustomStyle} [style] - The style of the input
 */

/**
 * @param {DropdownSelectProps} props
 * @returns {JSX.Element}
 */
const UnityDropdown = ({
  autofocus = false,
  expanded = undefined,
  label = "",
  placeholder = "Some placeholder",
  disabled = false,
  allowClear = false,
  bottomContent = null,
  showTooltip = false,
  showSearch = false,
  onSearchChange = noop,
  onPopupScroll = noop,
  name = "",
  inputType = "single-select",
  helperText = "",
  options = [],
  // boxType = "label",
  notFoundContent = "No data",
  id = "",
  selected = undefined,
  onValueChange = noop,
  onExpandedChange = noop,
  style = {},
  defaultValue = undefined,
}) => {
  const isMulti = inputType === "multi-select"
  const customVariablesOnly = Object.keys(style).reduce((acc, key) => {
    if (key.startsWith("--")) {
      acc[key] = style[key]
    }
    return acc
  }, {})

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 6,
        ...style,
      }}
      id={id}
    >
      {label ? (
        <UnityDropdownLabel htmlFor={name} id={`${id}-label`}>
          {label}
        </UnityDropdownLabel>
      ) : null}

      <StyledDropdown
        {...(expanded !== undefined ? { open: expanded } : {})}
        value={selected || undefined}
        onDropdownVisibleChange={onExpandedChange}
        autoFocus={autofocus}
        placeholder={placeholder}
        dropdownAlign={{
          offset: [0, 0],
        }}
        autoClearSearchValue={false}
        allowClear={
          allowClear
            ? {
              clearIcon: (
                <UnityIcon
                  Component={CloseIcon}
                  style={{
                    fill: disabled ? "A2AAAD" : "#4d4d4d",
                    marginTop: -10,
                  }}
                />
              ),
            }
            : false
        }
        disabled={disabled}
        showSearch={showSearch}
        onSearch={onSearchChange}
        onPopupScroll={onPopupScroll}
        filterOption={filterOption}
        defaultValue={defaultValue || undefined}
        dropdownStyle={{
          borderRadius: 0,
          border: "1px solid #DCDCDC",
          boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.25)",
          padding: 0,
          animationDuration: "0s",
          maxHeight: "100%",
        }}
        mode={isMulti ? "multiple" : "single"}
        suffixIcon={
          <UnityIcon
            Component={SortDownIcon}
            color={disabled ? "#A2AAAD" : "#4d4d4d"}
            style={{
              marginTop: -14,
            }}
          />
        }
        menuItemSelectedIcon={null}
        onChange={(v) => {
          if (Array.isArray(v)) {
            onValueChange(v[v.length - 1], v)
          } else {
            onValueChange(v, selected)
          }
        }}
        options={parseOptions(options, isMulti)}
        notFoundContent={
          <div
            style={{
              height: "44px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {notFoundContent}
          </div>
        }
        virtual={false}
        dropdownRender={(menu) => (
          <div style={customVariablesOnly}>
            <UnityDropdownOptionsList $multi={isMulti}>
              {menu}
              {helperText ? (
                <UnityDropdownHelperText>{helperText}</UnityDropdownHelperText>
              ) : null}

              {bottomContent ? (
                <div
                  onMouseDown={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                  style={{
                    borderTop: "1px solid #a2aaad",
                  }}
                >
                  {bottomContent}
                </div>
              ) : null}
            </UnityDropdownOptionsList>
          </div>
        )}
        optionRender={(option) => {
          return (
            <li id={`select-menu-option-${option.value}`} title={showTooltip ? `${option.label.props.children[1]}` : null}>{option.label}</li>
          )
        }}
        tagRender={({ label }) => <span>{label}, </span>}
      />
    </div>
  )
}

export default UnityDropdown
