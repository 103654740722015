import React from "react"

import {
  NotificationIcon,
  WarningCircleFillIcon, WarningCircleOutlineIcon,
  WarningTriangleFillIcon,
  WarningTriangleOutlineIcon
} from "assets/icons"
import UnityIcon from "components/unity/UnityIcon"


export const ROLE_SUPERADMIN = "superadmin"

export const ROLE_ADMIN = "admin"

export const ROLE_USER = "user"

export const ICON_OPTIONS = [
  {id: "none", label: "None", icon: ""},
  {id: "icon-notification", label: "Notification", icon: <UnityIcon Component={NotificationIcon} />},
  {id: "icon-warning-triangle-fill", label: "Warning Triangle Fill", icon: <UnityIcon Component={WarningTriangleFillIcon}/>},
  {id: "icon-warning-triangle-outline", label: "Warning Triangle Outline", icon: <UnityIcon Component={WarningTriangleOutlineIcon}/>},
  {id: "icon-warning-circle-fill", label: "Warning Circle Fill", icon: <UnityIcon Component={WarningCircleFillIcon}/>},
  {id: "icon-warning-circle-outline", label: "Warning Circle Outline", icon: <UnityIcon Component={WarningCircleOutlineIcon}/>}
]
