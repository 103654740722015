import { Popover } from "antd"
import { UnityButton } from "libs/UnityCoreReact"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"

import { getAltairOneStatus } from "actions/auth"
import { navigate } from "actions/navigate"
import UserPopoverContent from "components/Layout/UserPopoverContent"
import { A1_ACCOUNT_LINKING_ENABLED } from "constants/featureFlags"
import { addMessage, GLOBAL_NOTIFICATIONS, MESSAGE_TYPE_ERROR, MESSAGE_TYPE_SUCCESS } from "utils/notifications"


const showAltairOneLinkSuccessNotification = () => {
  addMessage({
    target: GLOBAL_NOTIFICATIONS,
    text: "AltairOne Licenses linked successfully",
    type: MESSAGE_TYPE_SUCCESS,
    timeout: 4000
  })
}

const showAltairOneLinkFailureNotification = () => {
  addMessage({
    target: GLOBAL_NOTIFICATIONS,
    text: "AltairOne Licenses linking failed",
    subtext: "Please try again later or contact support",
    type: MESSAGE_TYPE_ERROR,
    timeout: 4000
  })
}

const mapStateToProps = (state) => ({
  username: state.authentication.userInfo.username,
})

const UserPopover = ({
  username,
  getAltairOneStatus,
}) => {
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleOpenChange = async (newOpen) => {
    if (newOpen) {
      setIsLoading(true)
      setOpen(newOpen)
      if (A1_ACCOUNT_LINKING_ENABLED) await getAltairOneStatus()
      setIsLoading(false)
    } else {
      setOpen(newOpen)
    }
  }

  useEffect(() => {
    if (A1_ACCOUNT_LINKING_ENABLED) {
      const handleLinkAltairOne = (event) => {
        if (event.data.type === "LINK_ALTAIR_ONE") {
          if (event.data.success) {
            showAltairOneLinkSuccessNotification()
          } else {
            showAltairOneLinkFailureNotification()
          }

          setOpen(false)
        }
      }

      window.addEventListener("message", handleLinkAltairOne)

      return () => window.removeEventListener("message", handleLinkAltairOne)
    }
  }, [])

  return (
    <Popover
      placement="bottomRight"
      content={<UserPopoverContent isLoading={isLoading}/>}
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
      styles={{ body: { padding: "15px", minWidth: "350px" } }}
    >
      <UnityButton
        id="header-button-profile"
        label={username}
        leftIcon={"unity:user"}
        type="borderless"
        style={styles.headerButton}
      />
    </Popover>
  )
}

const styles = {
  headerButton: {
    justifyItems: "center",
    display: "inline-grid",
  }
}

export default connect(mapStateToProps, {navigate, getAltairOneStatus})(UserPopover)
