import {LoadingOutlined} from "@ant-design/icons"
import {Spin} from "antd"
import {UnityButton, UnityTypography} from "libs/UnityCoreReact"
import React, {useEffect, useRef, useState} from "react"
import {connect, useDispatch, useSelector} from "react-redux"


import {navigate} from "actions/navigate"
import {
  ErrorIcon,
  InfoCircleIcon,
  WarningCircleOutlineIcon,
} from "assets/icons"
import {colors} from "assets/stylesheets/common"
import ExpandableItem from "components/common/ExpandableItem"
import HelpTooltip from "components/common/HelpTooltip"
import {HELP_TIP_TEXT} from "constants/notifications"
import {labels, NOTIFICATIONS} from "constants/routes"
import {
  dismissAllNotifications,
  getNextNotifications,
  getNotifications,
  updateNotificationStatusToRead
} from "reduxModules/notifications"



const TITLE = labels[NOTIFICATIONS]

const mapStateToProps = (state) => ({
  notifications: state.notifications.notifications,
  paging: state.notifications.paging,
})

const NotificationsPopoverContent = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingMore, setIsLoadingMore] = useState(false)

  const dispatch = useDispatch()
  const containerRef = useRef(null)
  const {notifications, paging} = useSelector(state => state.notifications)

  useEffect(() => {
    const container = containerRef.current
    if (!container) return

    if (isLoadingMore) return

    const handleScroll = async () => {
      if (container.scrollTop + container.clientHeight >= container.scrollHeight - 50) {
        if (paging && paging.next_cursor && !isLoadingMore) {
          setIsLoadingMore(true)
          await dispatch(getNextNotifications())
          setIsLoadingMore(false)
        }
      }
    }

    container.addEventListener("scroll", handleScroll)
    return () => {
      container.removeEventListener("scroll", handleScroll)
    }

  }, [paging, dispatch, isLoadingMore])

  const handleRefresh = async () => {
    setIsLoading(true)
    await dispatch(getNotifications())
    setIsLoading(false)
  }

  const handleRemove = async (id) => {
    setIsLoading(true)
    await dispatch(updateNotificationStatusToRead(id))
    setIsLoading(false)
  }

  const handleRemoveAll = async () => {
    setIsLoading(true)
    await dispatch(dismissAllNotifications())
    setIsLoading(false)
  }

  return (
    <>
      <section style={styles.flexContainer}>
        <div style={styles.leftContainer}>
          <UnityTypography
            size="header1"
            weight="header1"
            color="dark"
          >
            {TITLE}
          </UnityTypography>
          <HelpTooltip
            title={HELP_TIP_TEXT}
            style={styles.descriptionBtn}
            placement="top"
          />
          <UnityButton
            id={"notifications-refresh-button"}
            leftIcon={"unity:refresh"}
            label={""}
            disabled={isLoading}
            type={"borderless"}
            onClick={handleRefresh}
            style={styles.refreshButton}
          />
        </div>
        <div style={styles.rightContainer}>
          <UnityButton
            id={"dismiss-all-notifications-button"}
            label={"Dismiss All"}
            disabled={isLoading}
            type={"secondary"}
            onClick={handleRemoveAll}
          />
        </div>
      </section>
      <div ref={containerRef} style={{...styles.wrapper, ...{width: "100%"}}}
        className={"expandable-item-container"}>
        {Array.isArray(notifications) && notifications?.map((notification) => {

          if (notification.status === "received") {
            let iconLevel = <InfoCircleIcon/>
            let colorLevel = colors.nav1
            if (notification.level === "critical") {
              iconLevel = <ErrorIcon/>
              colorLevel = colors.primary1
            } else if (notification.level === "warning") {
              iconLevel = <WarningCircleOutlineIcon/>
              colorLevel = colors.orangeShadow2
            }

            return (
              <ExpandableItem style={styles.expandableItem}
                key={notification.id}
                decoratorColor={colorLevel}
                icon={iconLevel}
                iconColor={colorLevel}
                hoveredColor="rgba(0, 87, 118, 0.07)"
                backgroundColor={colors.white}
                summaryText={notification.message}
                detailText={notification}
                detailTextType="json"
                closable
                onRemove={() => handleRemove(notification.id)}
              />)
          }
        }
        )
        }
        {(isLoading || isLoadingMore) && (
          <div style={styles.spinner}>
            <Spin indicator={<LoadingOutlined spin/>} size="large"/></div>
        )}
      </div>
    </>
  )
}

const styles = {
  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px",
    flexWrap: "nowrap"
  },
  leftContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  rightContainer: {
    display: "flex",
    gap: "10px",
  },
  refreshButton: {
    marginLeft: "0px",
    marginBottom: "3px",
    "--button-padding": "0px",
    "--icon-button-size": "25px",
    "--button-color": "var(--black-text-color, #000)"
  },
  descriptionBtn: {
    "--default-icon-button-size": "23px"
  },
  wrapper: {
    height: "calc(100vh - 60px - 100px)", // Total Viewport Height - Header - Footer
    overflowY: "scroll",
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
    gap: "10px",
    marginTop: "10px",
  },
  expandableItem: {
    width: "100%",
    maxWidth: "100%",
    boxSizing: "border-box",
    position: "relative",
  },
  spinner: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(162,170,173,0.1)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
    pointerEvents: "all",
    borderRadius: "1%",
  }
}

export default connect(mapStateToProps, {navigate})(NotificationsPopoverContent)
