import {
  CloseOutlined,
  DownOutlined, UpOutlined
} from "@ant-design/icons"
import { Col, Row } from "antd"
import React, { useState } from "react"

import "assets/stylesheets/components/expandableItem.css"
import {colors} from "assets/stylesheets/common"

const ExpandableItem = ({
  decoratorColor = "#1890ff",
  icon,
  iconColor = "#1890ff",
  hoveredColor = "#18d1ff",
  backgroundColor = colors.white,
  summaryText = "",
  detailText = {},
  detailTextType = "json",
  closable = false,
  radiusSize = 4,
  onRemove = () => {}
}) => {
  const [expanded, setExpanded] = useState(false)
  const [removed, setRemoved] = useState(false)
  const [hovered, setHovered] = useState(false)

  if (removed) {
    return null
  }

  const toggleExpand = () => {
    setExpanded(!expanded)
  }

  const handleClose = (e) => {
    e.stopPropagation()
    setRemoved(true)
    onRemove()
  }

  return (
    <div className="expandable-item-wrapper"
      onMouseEnter={() => {if (closable) setHovered(true)}}
      onMouseLeave={() => {if (closable) setHovered(false)}}
    >
      <Row
        align="middle"
        className="expandable-item-internal-container"
        style={{borderRadius: radiusSize, backgroundColor: hovered ? hoveredColor: backgroundColor}}
        onClick={toggleExpand}
      >
        {closable && hovered && (
          <div
            onClick={handleClose}
            className="expandable-item-cross-close-container"
          >
            <CloseOutlined className="expandable-item-cross-close-icon" />
          </div>
        )}

        <Col
          className="expandable-item-decorator"
          style={{backgroundColor: decoratorColor, borderBottomLeftRadius: radiusSize, borderTopLeftRadius: radiusSize}}
        />

        <Col className="expandable-item-icon" style={{fill: iconColor}}>
          {icon}
        </Col>

        <Col className="expandable-item-summary-ellipsis">
          {summaryText}
        </Col>

        <Col className="expandable-item-arrow">
          {expanded ? <UpOutlined /> : <DownOutlined />}
        </Col>
      </Row>

      {expanded && (
        <Row className="expandable-item-details" style={{borderRadius: radiusSize }}>
          <Col span={24}>
            <pre className="expandable-item-details-content" style={styles.detailsContent}>
              {detailTextType === "json"? JSON.stringify(detailText, null, 5): detailText }
            </pre>
          </Col>
        </Row>
      )}
    </div>
  )
}

const styles = {
  detailsContent:{
    fontFamily: "var(--font-face, var(--font-family))",
    fontSize: "var(--font-size, var(--paragraph-font-size))",
    fontWeight: "var(--font-weight, var(--paragraph-font-weight))",
    color: "var(--font-color, var(--black-color))",
  }
}

export default ExpandableItem