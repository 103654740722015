import React, {useEffect } from "react"
import {connect, useDispatch, useSelector} from "react-redux"

import BannerMessage from "components/common/BannerMessage"
import {getBanners} from "reduxModules/admin.js"

const mapStateToProps = (state) => ({
  banners: state.admin.banners
})

const BannersHandler = ({
  getBanners
}) => {

  const dispatch = useDispatch()
  const banners = useSelector((state) => state.admin.banners)

  useEffect(() => {
    async function fetchBanners() {
      await dispatch(getBanners)
    }
    fetchBanners()
  }, [dispatch])


  const isBannerActive = (banner) => {
    const today = new Date()
    const startDate = new Date(banner.start_date)
    const dueDate = new Date(banner.due_date)
    return startDate < today && today < dueDate
  }
  return (
    <>
      {banners.filter(isBannerActive).map((banner) => {
        return (<BannerMessage key={banner.id}
          message={banner.message}
          background={banner.background}
          textColor={banner.text_color}
          bannerIcon={banner.banner_icon}
        />)
      })}
    </>
  )
}

export default connect(mapStateToProps, {getBanners})(BannersHandler)