import {Popover } from "antd"
import { UnityButton } from "libs/UnityCoreReact"
import React, {useEffect, useState} from "react"
import {connect, useDispatch, useSelector} from "react-redux"

import {labels, NOTIFICATIONS} from "constants/routes"
import {getNotifications} from "reduxModules/notifications"

import NotificationsPopoverContent from "./NotificationsPopoverContent"


const mapStateToProps = (state) => ({
  notifications: state.notifications.notifications,
  location: state.router.location
})

const NotificationsPopover = ({
  getNotifications,
}) => {
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [alert, setAlert] = useState(false)

  const dispatch = useDispatch()
  const notifications = useSelector((state) => state.notifications.notifications)

  useEffect(() => {
    async function fetchNotifications() {
      await dispatch(getNotifications)
    }
    fetchNotifications()
  }, [dispatch])

  useEffect(() => {
    setAlert(notifications.length > 0)
  }, [notifications])

  const handleOpenChange = async (newOpen) => {
    if (newOpen) {
      setIsLoading(true)
      setOpen(newOpen)
      await dispatch(getNotifications)
      setIsLoading(false)
    } else {
      setOpen(newOpen)
    }
  }

  return (
    <Popover
      placement="bottomRight"
      content={<NotificationsPopoverContent isLoading={isLoading}/>}
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
      autoAdjustOverflow
      styles={{ body: styles.popOver }}
    >
      <UnityButton
        id="header-button-notifications"
        label={labels[NOTIFICATIONS]}
        leftIcon={"unity:notification"}
        type="borderless"
        style={alert? styles.notificationsNotExistAlerts : styles.notificationsExistAlerts}
      />
    </Popover>
  )
}

const styles = {
  popOver: {
    padding: "15px",
    minWidth: "400px",
    maxWidth: "400px"
  },
  notificationsExistAlerts: {
    justifyItems: "center",
    display: "inline-grid",
    opacity: 0.5,
  },
  notificationsNotExistAlerts: {
    justifyItems: "center",
    display: "inline-grid",
    "--default-button-borderless-text-color": "var(--primary-brand-color, #000)",
    "--button-color": "var(--primary-brand-color, #000)",
    "--pressed-color": "var(--primary-brand-color, #000)",
  }
}

export default connect(mapStateToProps, {getNotifications})(NotificationsPopover)
