/* == ACTIONS === */
import {createReducer} from "@reduxjs/toolkit"

import {CLEAN_SPACE} from "actions/auth"
import {makeActions} from "reduxModules/utiliducks"
import {addMessage, MESSAGE_TYPE_ERROR} from "utils/notifications"
import {
  getIntroVideoRequest,
  getMediaWebinarsRequest, getReleasesNotesRequest,
  getTutorialsRequest,
  getUseCasesRequest
} from "utils/statics/home"

const actionList = [
  "setIntroVideoAction",
  "setMediaWebinarsAction",
  "setUseCasesAction",
  "setTutorialsAction",
  "setReleasesNotesAction",
]
const {
  setIntroVideoAction,
  setMediaWebinarsAction,
  setUseCasesAction,
  setTutorialsAction,
  setReleasesNotesAction,
} = makeActions("home", actionList)

/* === INITIAL STATE === */
const initialState = {
  introVideo: {},
  mediaWebinar: [],
  useCases: [],
  tutorials: [],
  releasesNotes: [],
}

/* === Reducer === */
export default createReducer(initialState, {
  [setIntroVideoAction]: (state, { payload: { introVideo }}={}) => ({
    ...state,
    introVideo,
  }),
  [setMediaWebinarsAction]: (state, { payload: { mediaWebinar } }) => ({
    ...state,
    mediaWebinar,
  }),
  [setUseCasesAction]: (state, { payload: { useCases } }) => ({
    ...state,
    useCases
  }),
  [setTutorialsAction]: (state, { payload: { tutorials } }) => ({
    ...state,
    tutorials
  }),
  [setReleasesNotesAction]: (state, { payload: { releasesNotes } }) => ({
    ...state,
    releasesNotes
  }),
  [CLEAN_SPACE]: () => initialState
})

/* === DISPATCHERS === */
export const getIntroVideoData = () => {
  return async (dispatch) => {
    try {
      const response = await getIntroVideoRequest()
      dispatch(setIntroVideoAction({ introVideo: response }))
      return response
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        text: "Video info could not be retrieved",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const getMediaWebinarsData = () => {
  return async (dispatch) => {
    try {
      const response = await getMediaWebinarsRequest()
      dispatch(setMediaWebinarsAction({ mediaWebinar: response }))
      return response
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        text: "Media Webinars info could not be retrieved",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const getUseCasesData = () => {
  return async (dispatch) => {
    try {
      const response = await getUseCasesRequest()
      dispatch(setUseCasesAction({ useCases: response }))
      return response
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        text: "Use Cases info could not be retrieved",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const getTutorialsData = () => {
  return async (dispatch) => {
    try {
      const response = await getTutorialsRequest()
      dispatch(setTutorialsAction({ tutorials: response }))
      return response
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        text: "Tutorials info could not be retrieved",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const getReleasesNotesData = () => {
  return async (dispatch) => {
    try {
      const response = await getReleasesNotesRequest()
      dispatch(setReleasesNotesAction({ releasesNotes: response }))
      return response
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        text: "Release Notes info could not be retrieved",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}