/* === IMPORTS === */
import {createReducer} from "@reduxjs/toolkit"

import {makeLib} from "utils/misc"
import {getNotificationsRequest, updateNotificationRequest, updateNotificationsRequest} from "utils/notification"
import {addMessage, GLOBAL_NOTIFICATIONS, MESSAGE_TYPE_ERROR,} from "utils/notifications"

import {makeActions} from "./utiliducks"

/* == ACTIONS === */
const actionList = [
  "setNotificationsAction",
  "updateNotificationAction",
  "setPagingAction",
]
const {
  setNotificationsAction,
  updateNotificationAction,
  setPagingAction,
} = makeActions("notifications", actionList)

/* === INITIAL STATE === */
const initialState = {
  notifications: [],
  notificationsLib: {},
  paging: { previous_cursor: "", next_cursor: "" },
}

/* === Reducer === */
export default createReducer(initialState, {
  [setNotificationsAction]: (state, { payload: { notifications }}={}) => {
    return {
      ...state,
      notifications: notifications,
      notificationsLib: makeLib({data: notifications}),
    }
  },
  [updateNotificationAction]: (state, { payload: { id, notification: updatedNotification }}) => {
    return {
      ...state,
      notifications: state.notifications.map(notif => notif.id === id? updatedNotification: notif),
      notificationsLib: {
        ...state.notificationsLib,
        [id]: {
          ...state.notificationsLib[id], ...updatedNotification
        }
      },
    }
  },
  [setPagingAction]: (state, { payload: { paging } }) => ({
    ...state,
    paging: paging
  })
})


/* === DISPATCHERS === */
export const getNotifications = () => {
  return async dispatch => {
    try {
      const response = await getNotificationsRequest("?status=received")
      const { data, paging } = response
      const notifications = data ? data : response
      dispatch(setNotificationsAction({notifications}))
      if (paging) dispatch(setPaging({paging}))
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      if (!error.message.includes("Access credentials are not sufficient to access this resource")) {
        addMessage({
          target: GLOBAL_NOTIFICATIONS,
          text: "Notifications could not be retrieved",
          subtext: error.message,
          type: MESSAGE_TYPE_ERROR
        })
      }
    }
  }
}

export const getNextNotifications = () => {
  return async (dispatch, getState) => {
    const {notifications} = getState()

    const { data, paging } = await getNotificationsRequest({next_cursor: notifications.paging.next_cursor})

    dispatch(setPaging({paging}))
    dispatch(setNotificationsAction({ notifications: [...notifications.notifications, ...data]}))
  }
}

export const dismissAllNotifications = () => {
  return async (dispatch, getState) => {
    try {
      const {notifications} = getState()

      let body = []
      Array.isArray(notifications?.notifications) && notifications?.notifications?.map(notification => {
        body.push({
          id: notification.id,
          status: "read"
        })
      })

      if (body.length === 0) {
        return
      }

      await updateNotificationsRequest(body)
      await dispatch(getNotifications())
    }catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Notification could not be updated",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const updateNotificationStatusToRead = (id) => {
  return async dispatch => {
    try {
      const body = {status: "read"}
      const response = await updateNotificationRequest(id, body)
      return dispatch(updateNotificationAction({id, notification: response}))
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Notification could not be updated",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

/* === UTILS === */
export const setPaging = ({ paging }) => setPagingAction({ paging })
