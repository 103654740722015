import React from "react"

import { CheckIcon } from "assets/icons"
import UnityIcon from "components/unity/UnityIcon"

import UnityDropdownCheckbox from "./UnityDropdownCheckbox"


const parseOptions = (options, isMulti) => {
  return options.map((option) => {
    if (typeof option === "string") {
      return {
        label: option,
        value: option,
        id: option,
      }
    }

    if (typeof option === "object") {
      const showCheckbox = isMulti && !option.options
      const optionObject = {}

      if (option.label) {
        optionObject.label =
        <div style={{display: "inline-flex", gap:4, alignItems: "center"}}>
          {showCheckbox ?
            <UnityDropdownCheckbox disabled={option.disabled}>
              <UnityIcon Component={CheckIcon} color={"white"}/>
            </UnityDropdownCheckbox> : null}
          {option.icon}
          {option.label || option.id}
        </div>
      }

      if (option.id) {
        optionObject.value = option.id
      }

      if (option.htmlId || option.id) {
        optionObject.id = option.htmlId || option.id
      }

      if (option.options) {
        optionObject.options = parseOptions(option.options)
      }

      if (option.disabled) {
        optionObject.disabled = option.disabled
      }

      return optionObject
    }

    return option
  })
}

export default parseOptions
