import {Layout} from "antd"
import { getLocation } from "connected-react-router"
import React, { lazy, Suspense, useState } from "react"
import { connect } from "react-redux"
import {
  Redirect,
  Route,
  Switch
} from "react-router-dom"

// all page references
import SideNavAdmin from "components/AdminView/SideNavAdmin"
import App from "components/App/App"
import AppSpace from "components/AppSpace/AppSpace"
import Documentation from "components/Documentation/Documentation"
import LayoutHeader from "components/Layout/LayoutHeader"
import LogOut from "components/Onboarding/LogOut"
import Authenticate from "components/Onboarding/SignIn/Authenticate"
import SignIn from "components/Onboarding/SignIn/SignIn"
import PrivateRoute from "components/PrivateRoute"
import SideNav from "components/SideNav/SideNav"
import UsageHistoryChart from "components/UsageHistoryChart/UsageHistoryChart"
import UtilityBelt from "components/UtilityBelt/UtilityBelt"
import { A1_ACCOUNT_LINKING_ENABLED, BOUNDARIES_ENABLED } from "constants/featureFlags"
import {
  ACCESS_CONTROL,
  ADMIN, ADMIN_BANNERS, ADMIN_SPACES,
  ADMIN_USERS,
  ANYTHING_DB,
  APP,
  APPS,
  ASSET_CONFIGURATIONS,
  AU_MANAGEMENT,
  AUTHENTICATE,
  CATEGORIES,
  CLUSTER_MANAGEMENT,
  CUSTOM_QUERIES,
  DATA_VIZ,
  DISTRIBUTIONS,
  DOCS_POLICY_RESOURCES,
  ECP_IMAGES,
  EDGE_APPS,
  EDGE_OPS,
  EDGE_PROVISIONINGS,
  EVERY_THING,
  FUNCTIONS,
  GRAPH,
  HIGH_FREQ_DATA,
  HOME_PAGE,
  LABELS,
  LINK_ALTAIR_ONE,
  LOGIN,
  LOGOUT,
  MODELS,
  OBJECT_STORAGE,
  PACKAGES, PACKAGES_EDGE_APPS, PACKAGES_OBJECTS,
  PROPERTIES_HISTORY_CHART,
  RESOURCES,
  ROLES,
  ROLLOUTS,
  ROOT,
  SERVERLESS,
  SPACE_GENERAL,
  SPACE_PICKER,
  SPACE_SETTINGS,
  STREAMS,
  TRIGGERS,
  USAGE_HISTORY_CHART,
  USERS,
  VARIABLES_STORAGE
} from "constants/routes"
import { routes } from "utils/routes"

import BannersHandler from "./components/Layout/BannersHandler"


const { Header, Content} = Layout
const AppsList = lazy(() => import("components/AccessControl/Apps/AppsList"))
const AUManagement = lazy(() => import("components/AUManagement/AUManagement"))
const EcpImageList = lazy(() => import("components/EdgeOrchestration/EcpImages/EcpImageList"))
const AssetConfigurations = lazy(()=> import("components/EdgeOrchestration/AssetConfigurations/AssetConfigurations"))
const DistributionsList = lazy(() => import("components/EdgeOrchestration/Distributions/DistributionsList"))
const EdgeAppList = lazy(() => import("components/EdgeOrchestration/EdgeApps/EdgeAppList"))
const PackagesEdgeAppsList = lazy(() => import("components/Packages/EdgeApps/EdgeAppList"))
const PackagesObjectStorageList = lazy(() => import("components/Packages/Objects/ObjectsList"))
const ClusterListBeta = lazy(() => import("components/EdgeOrchestration/ClustersBeta/ClusterListBeta"))
const Designer = lazy(() => import("components/Panopticon/Designer"))
const FunctionList = lazy(() => import("components/Functions/FunctionList"))
const HighFreqDataList = lazy(() => import("components/Settings/HighFrequencyData/HfdList"))
const Homepage = lazy(() => import("components/Homepage/Homepage"))
const LabelList = lazy(() => import("components/Labels/LabelList"))
const ObjectStorage = lazy(() => import("components/ObjectStorage/ObjectStorage"))
const AdminUserList = lazy(() => import("components/AdminView/AdminUserList"))
const AdminSpacesList = lazy(() => import("components/AdminView/SpacesList"))
const AdminBanners = lazy(() => import("components/AdminView/Banners/BannersList"))
const ResourceList = lazy(() => import("components/EdgeOrchestration/ResourceTemplates/ResourceList"))
const EdgeProvisioningsList = lazy(() => import("components/EdgeOrchestration/EdgeProvisionings/EdgeProvisioningsList"))
const RolesList = lazy(() => import("components/AccessControl/Roles/RolesList"))
const RolloutsList = lazy(() => import("components/EdgeOrchestration/Rollouts/RolloutsList"))
const VariablesStorage = lazy(() => import("components/Settings/VariablesStorage/VariablesStorage"))
const Spaces = lazy(() => import("components/Spaces/Spaces"))
const SpaceDetails = lazy(() => import("components/Spaces/SpaceDetails"))
const Streams = lazy(() => import("components/Panopticon/Streams"))
const TriggerList = lazy(() => import("components/Functions/Triggers/TriggerList"))
const UsersList = lazy(() => import("components/AccessControl/Users/UsersList"))
const Categories = lazy(() => import("components/AnythingDb/Categories/Categories"))
const GraphSection = lazy(() => import("components/AnythingDb/Graph/GraphSection"))
const Models = lazy(() => import("components/AnythingDb/Models/Models"))
const Everything = lazy(() => import("components/AnythingDb/Everything"))
const CustomQueries = lazy(() => import("components/AnythingDb/CustomQueries/CustomQueries"))
const LinkAltairOne = lazy(() => import("components/LinkAltairOne/LinkAltairOne"))
const PropertiesHistoryChart = lazy(() => import("components/PropertiesHistoryChart/PropertiesHistoryChart"))

// Reference the route to your page as routes[route_name]
// sub-pages will be the {children} (as expected of React), using Switch/Route format
// if new Switch, make sure to include default w/ Redirect
// a route with a url var will act as a wildcard, all specific routes must be above it in the switch
// when redirecting to a url var (ex: :orgCd) the path must already have that var defined
// `component` receives {match, location} automatically
//    there are two path based args: match and location
//      match: the path up to the when the page renders, will have {params} with all viable url vars
//        ex: match in routes[ORG] for `organization/:orgCd` will show only up to `organization`
//        ex: above in routes[ORGCD] will show `organization/:orgCd` and have params: {orgCd: 'orgCd'}
//      location: the full path rendered, but doesn't separate url vars out manually
//        ex: location in routes[ORG] for `organization/:orgCd/manage/production-things` will show whole url
function Routes({ location }) {

  const [authorized, setAuthorized] = useState(false)

  const handleSetAuthorized = (value) => setAuthorized(value)

  return (

    <Switch location={location}>
      { A1_ACCOUNT_LINKING_ENABLED &&
        <Route location={location} exact path={routes[LINK_ALTAIR_ONE]}>
          <Suspense fallback={<div></div>}>
            <LinkAltairOne />
          </Suspense>
        </Route>
      }

      <Route location={location} exact path={routes[PROPERTIES_HISTORY_CHART]}>
        <Suspense fallback={<div></div>}>
          <PropertiesHistoryChart />
        </Suspense>
      </Route>

      <Route location={location} exact path={routes[USAGE_HISTORY_CHART]}>
        <Suspense fallback={<div></div>}>
          <UsageHistoryChart />
        </Suspense>
      </Route>

      <PrivateRoute location={location} path={routes[SPACE_PICKER]}>
        <Suspense fallback={<div></div>}>
          <Layout hasSider={false}>
            <Header style={{ ...styles.header }}><LayoutHeader /></Header>
            <BannersHandler />
            <Content>
              <Spaces authorized={authorized}/>
            </Content>
          </Layout>
        </Suspense>
      </PrivateRoute>

      <PrivateRoute location={location} path={routes[ADMIN]}>
        <Layout hasSider={false}>
          <Header style={{ ...styles.header }}><LayoutHeader /></Header>
          <BannersHandler />
          <Content>
            <SideNavAdmin>
              <Suspense fallback={<div></div>}>
                <Switch location={location}>
                  <PrivateRoute location={location} path={routes[ADMIN_USERS]}>
                    <AdminUserList />
                  </PrivateRoute>
                  <PrivateRoute location={location} path={routes[ADMIN_SPACES]}>
                    <AdminSpacesList />
                  </PrivateRoute>
                  <PrivateRoute location={location} path={routes[ADMIN_BANNERS]}>
                    <AdminBanners />
                  </PrivateRoute>
                </Switch>
              </Suspense>
            </SideNavAdmin>
          </Content>
        </Layout>
      </PrivateRoute>


      <PrivateRoute location={location} path={routes[APP]}>
        <Layout hasSider={false}>
          <Header style={{ ...styles.header }}><LayoutHeader /></Header>
          <BannersHandler />
          <Content>
            <AppSpace authorized={authorized} setAuthorized={handleSetAuthorized}>
              <SideNav authorized={authorized}>
                <UtilityBelt authorized={authorized}>
                  <Suspense fallback={<div></div>}>
                    <Switch location={location}>
                      { authorized &&
                        <PrivateRoute location={location} path={routes[ANYTHING_DB]}>
                          <Switch location={location}>
                            <PrivateRoute location={location} path={routes[CATEGORIES]}>
                              <Categories />
                            </PrivateRoute>
                            <PrivateRoute location={location} path={routes[GRAPH]}>
                              <GraphSection />
                            </PrivateRoute>
                            <PrivateRoute location={location} path={routes[MODELS]}>
                              <Models />
                            </PrivateRoute>
                            <PrivateRoute location={location} path={routes[EVERY_THING]}>
                              <Everything />
                            </PrivateRoute>
                            <PrivateRoute location={location} path={routes[CUSTOM_QUERIES]}>
                              <CustomQueries />
                            </PrivateRoute>
                            <Redirect to={routes[EVERY_THING]} />
                          </Switch>
                        </PrivateRoute>
                      }
                      { authorized &&
                        <PrivateRoute location={location} path={routes[EDGE_OPS]}>
                          <Switch location={location} >
                            <PrivateRoute location={location} path={routes[CLUSTER_MANAGEMENT]}>
                              <ClusterListBeta />
                            </PrivateRoute>
                            <PrivateRoute location={location} path={routes[RESOURCES]}>
                              <ResourceList />
                            </PrivateRoute>
                            <PrivateRoute location={location} path={routes[EDGE_APPS]}>
                              <EdgeAppList />
                            </PrivateRoute>
                            <PrivateRoute location={location} path={routes[EDGE_PROVISIONINGS]}>
                              <EdgeProvisioningsList />
                            </PrivateRoute>
                            <PrivateRoute location={location} path={routes[ECP_IMAGES]}>
                              <EcpImageList />
                            </PrivateRoute>
                            <PrivateRoute location={location} path={routes[ASSET_CONFIGURATIONS]}>
                              <AssetConfigurations />
                            </PrivateRoute>
                            <PrivateRoute location={location} path={routes[DISTRIBUTIONS]}>
                              <DistributionsList />
                            </PrivateRoute>
                            <PrivateRoute location={location} path={routes[ROLLOUTS]}>
                              <RolloutsList />
                            </PrivateRoute>
                            <Redirect to={routes[CLUSTER_MANAGEMENT]} />
                          </Switch>
                        </PrivateRoute>
                      }
                      { authorized &&
                        <PrivateRoute location={location} path={routes[PACKAGES]}>
                          <Switch location={location}>
                            <PrivateRoute location={location} exact path={routes[PACKAGES_EDGE_APPS]}>
                              <PackagesEdgeAppsList/>
                            </PrivateRoute>
                            <PrivateRoute location={location} exact path={routes[PACKAGES_OBJECTS]}>
                              <PackagesObjectStorageList/>
                            </PrivateRoute>
                          </Switch>
                        </PrivateRoute>
                      }
                      { authorized &&
                        <PrivateRoute location={location} path={routes[SERVERLESS]}>
                          <Switch location={location}>
                            <PrivateRoute location={location} exact path={routes[FUNCTIONS]}>
                              <FunctionList/>
                            </PrivateRoute>
                            <PrivateRoute location={location} exact path={routes[TRIGGERS]}>
                              <TriggerList/>
                            </PrivateRoute>
                          </Switch>
                        </PrivateRoute>
                      }
                      { authorized &&
                        <PrivateRoute location={location} path={routes[STREAMS]}>
                          <Streams/>
                        </PrivateRoute>
                      }
                      { authorized &&
                        <PrivateRoute location={location} path={routes[DATA_VIZ]}>
                          <Designer/>
                        </PrivateRoute>
                      }
                      { authorized &&
                        <PrivateRoute location={location} path={routes[ACCESS_CONTROL]}>
                          <Switch location={location}>
                            <PrivateRoute location={location} admin path={routes[USERS]} >
                              <UsersList />
                            </PrivateRoute>
                            <PrivateRoute location={location} admin path={routes[ROLES]} >
                              <RolesList />
                            </PrivateRoute>
                            <PrivateRoute location={location} admin path={routes[APPS]} >
                              <AppsList />
                            </PrivateRoute>
                            <Redirect to={routes[APP]} />
                          </Switch>
                        </PrivateRoute>
                      }
                      { authorized &&
                        <PrivateRoute location={location} exact path={routes[LABELS]}>
                          <LabelList/>
                        </PrivateRoute>
                      }
                      { authorized &&
                        <PrivateRoute location={location} exact path={routes[OBJECT_STORAGE]}>
                          <ObjectStorage/>
                        </PrivateRoute>
                      }

                      <PrivateRoute location={location} path={routes[HOME_PAGE]}>
                        <Homepage/>
                      </PrivateRoute>

                      <PrivateRoute location={location} path={routes[SPACE_SETTINGS]}>
                        <Switch location={location}>
                          { authorized &&
                            <PrivateRoute location={location} path={routes[SPACE_GENERAL]}>
                              <SpaceDetails/>
                            </PrivateRoute>
                          }
                          { authorized &&
                            <PrivateRoute location={location} path={routes[HIGH_FREQ_DATA]}>
                              <HighFreqDataList/>
                            </PrivateRoute>
                          }
                          { authorized &&
                            <PrivateRoute location={location} path={routes[VARIABLES_STORAGE]}>
                              <VariablesStorage/>
                            </PrivateRoute>
                          }
                          { BOUNDARIES_ENABLED &&
                            <>
                              <PrivateRoute location={location} path={routes[AU_MANAGEMENT]}>
                                <AUManagement/>
                              </PrivateRoute>
                              <Redirect to={routes[AU_MANAGEMENT]} />
                            </>
                          }
                          <Redirect to={routes[SPACE_GENERAL]} />
                        </Switch>
                      </PrivateRoute>

                      <PrivateRoute location={location} path={routes[DOCS_POLICY_RESOURCES]}>
                        <Documentation doc={DOCS_POLICY_RESOURCES} />
                      </PrivateRoute>

                      <Redirect to={routes[HOME_PAGE]} />
                    </Switch>
                  </Suspense>
                </UtilityBelt>
              </SideNav>
            </AppSpace>
          </Content>
        </Layout>
      </PrivateRoute>

      <Route location={location} path={routes[ROOT]}>
        <App>
          <Switch location={location}>
            <Route location={location} exact path={routes[LOGIN]}>
              <SignIn />
            </Route>
            <Route location={location} exact path={routes[AUTHENTICATE]} component={Authenticate} />

            <Route location={location} exact path={routes[LOGOUT]}>
              <LogOut authorized={authorized}/>
            </Route>
            <Redirect to={routes[APP]} />
          </Switch>
        </App>
      </Route>

      <Redirect to={routes[LOGIN]} />
    </Switch>

  )}

const stateToProps = state => ({ location: getLocation(state) })
export default connect(stateToProps)(Routes)

const styles = {

  header: {
    borderBottom: "1px solid var(--gray-color)",
    background: "#FFFFFF",
    height: "32px",
    lineHeight: "32px",
    paddingInline: "10px",
  }
}