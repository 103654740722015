import { UnityButton } from "libs/UnityCoreReact"
import noop from "lodash/noop"
import React from "react"

import UnityTooltip from "components/unity/UnityTooltip"


function HelpTooltip({
  height=16,
  id="",
  key="",
  onClick=noop,
  placement="top",
  style={},
  title="",
  width=16,
  ...props
}) {
  return (
    <UnityTooltip title={title} placement={placement} {...props}>
      <UnityButton
        id={id}
        key={key}
        height={height}
        width={width}
        style={style}
        type="borderless"
        centerIcon="unity:help"
        onClick={onClick}
      />
    </UnityTooltip>
  )
}

export default HelpTooltip
