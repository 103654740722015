import React from "react"

import { colors } from "assets/stylesheets/common"

import nameTitles from "./utils/nameTitles"

const UsageHistoryChartTitle = ({ name = "" }) => (
  <span
    id={`${name}-title`}
    style={{
      ...styles.title,
      fontWeight: 800,
      color: colors.text1,
    }}
  >
    {nameTitles[name] + " (last 30 days)"}
  </span>
)

const styles = {
  title: {
    fontSize: 16,
    color: colors.text1,
    fontWeight: 800,
  },
  propertyType: {
    fontFamily: "NotoMono, monospace",
    color: colors.text3,
    fontSize: 12,
    fontWeight: 400,
  },
}

export default UsageHistoryChartTitle
