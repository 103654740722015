/* === IMPORTS === */
import { createReducer } from "@reduxjs/toolkit"

import { CLEAN_SPACE } from "actions/auth"
import {
  createBannerRequest,
  deleteBannerRequest,
  getBannersRequest, getFilterQueryParams,
  getSortQuery, getSpacesRequest,
  getUsersRequest,
  updateBannerRequest
} from "utils/admin"
import {parseApiErrorMessage} from "utils/api"
import { getSpacesStatusRequest } from "utils/auManagement"
import { getUsersAltairOneStatusRequest } from "utils/auth"
import { makeLib } from "utils/misc"
import {
  addMessage, GLOBAL_NOTIFICATIONS,
  MESSAGE_TYPE_ERROR, MESSAGE_TYPE_SUCCESS
} from "utils/notifications"
import { getTableSort } from "utils/storage"

import { makeActions } from "./utiliducks"



/* == ACTIONS === */
const actionList = [
  "setUsersAction",
  "setUsersPagingAction",
  "setSpacesAction",
  "setSpacesPagingAction",
  "setBannersAction",
  "setBannersPagingAction",
  "addBannerAction",
  "updateBannerAction",
  "deleteBannerAction",
]
const {
  setUsersAction,
  setUsersPagingAction,
  setSpacesAction,
  setSpacesPagingAction,
  setBannersAction,
  setBannersPagingAction,
  addBannerAction,
  updateBannerAction,
  deleteBannerAction,
} = makeActions("admin", actionList)

/* === INITIAL STATE === */
const initialState = {
  users: [],
  usersLib: {},
  usersPaging: { previous_cursor: "", next_cursor: "" },
  spaces: [],
  spacesLib: {},
  spacesPaging: { previous_cursor: "", next_cursor: "" },
  banners: [],
  bannersLib: {},
  bannersPaging: { previous_cursor: "", next_cursor: "" },
}

/* === Reducer === */
export default createReducer(initialState, {
  [setUsersAction]: (state, { payload: { users }}={}) => ({
    ...state,
    users,
    usersLib: makeLib({data: users}),
  }),
  [setUsersPagingAction]: (state, { payload: { paging } }) => ({
    ...state,
    usersPaging: paging
  }),
  [setSpacesAction]: (state, { payload: { spaces }}={}) => ({
    ...state,
    spaces,
    spacesLib: makeLib({data: spaces}),
  }),
  [setSpacesPagingAction]: (state, { payload: { paging } }) => ({
    ...state,
    spacesPaging: paging
  }),
  [setBannersAction]: (state, { payload: { banners }}={}) => ({
    ...state,
    banners,
    bannersLib: makeLib({data: banners}),
  }),
  [setBannersPagingAction]: (state, { payload: { paging } }) => ({
    ...state,
    bannersPaging: paging
  }),
  [addBannerAction]: (state, { payload: { bannerData } }) => ({
    ...state,
    banners: [
      ...state.banners,
      bannerData
    ],
    bannersLib: {
      ...state.bannersLib,
      [bannerData.id]: bannerData
    }
  }),
  [updateBannerAction]: (state, { payload: { id, bannerData } }) => ({
    ...state,
    banners: state.banners.map(banner => banner.id === id ? bannerData : banner),
    bannersLib: {
      ...state.bannersLib,
      [id]: {
        ...state.bannersLib[id],
        ...bannerData
      }
    }
  }),
  [deleteBannerAction]: (state, { payload: { bannerId="" } }) => {
    const banners = state.banners.filter(({ id }) => bannerId !== id)
    return {
      ...state,
      banners,
      bannersLib: makeLib({ data: banners })
    }
  },
  [CLEAN_SPACE]: () => initialState
})

/* === DISPATCHERS === */
export const getUsers = (params) => {
  return async (dispatch) => {
    try {
      const response = await getUsersRequest(params)
      const { data: users, paging } = response

      const usersIds = users.map(user => user.id)
      const usersLinkedInfo = await getUsersAltairOneStatusRequest(usersIds)
      const updatedUsers = assignLinkedInfoToUsers(users, usersLinkedInfo.data)

      dispatch(setUsersAction({ users: updatedUsers }))
      if (paging) dispatch(setUsersPagingAction({ paging }))
      return updatedUsers
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        text: "Users could not be retrieved",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const getNextUsers = (params) => {
  return async (dispatch, getState) => {
    const {
      admin: {
        users,
        usersPaging,
      }
    } = getState()

    try {
      const { data, paging } = await getUsersRequest({
        ...params,
        next_cursor: usersPaging.next_cursor,
      })

      const spacesIds = data.map(space => space.id)

      const spacesStatus = await getUsersAltairOneStatusRequest(spacesIds)
      const updatedUsers = assignLinkedInfoToUsers(data, spacesStatus.data)


      dispatch(setUsersAction({ users: [...users,...updatedUsers]}))
      if (paging) dispatch(setUsersPagingAction({ paging }))
      return updatedUsers
    } catch (error) {
      console.error(`${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Users could not be retrieved",
        type: MESSAGE_TYPE_ERROR,
      })
    }

  }
}

export const getSpaces = (params) => {
  return async (dispatch) => {
    try {
      const response = await getSpacesRequest(params)
      const { data: spaces, paging } = response

      const spacesIds = spaces.map(space => space.id)
      const spacesStatus = await getSpacesStatusRequest(spacesIds)
      const updatedSpaces = assignStatusToSpaces(spaces, spacesStatus.data, params)

      dispatch(setSpacesAction({ spaces: updatedSpaces }))
      if (paging) dispatch(setSpacesPagingAction({ paging }))
      return spaces
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        text: "Spaces could not be retrieved",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const getNextSpaces = (params) => {
  return async (dispatch, getState) => {
    const {
      admin: {
        spaces,
        spacesPaging,
      }
    } = getState()

    try {
      const { data, paging } = await getSpacesRequest({
        ...params,
        next_cursor: spacesPaging.next_cursor,
      })

      const spacesIds = data.map(space => space.id)

      const spacesStatus = await getSpacesStatusRequest(spacesIds)
      const updatedSpaces = assignStatusToSpaces(data, spacesStatus.data, params)
      dispatch(setSpacesAction({ spaces: [...spaces,...updatedSpaces]}))
      if (paging) dispatch(setSpacesPagingAction({ paging }))
      return updatedSpaces
    } catch (error) {
      console.error(`${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Spaces could not be retrieved",
        type: MESSAGE_TYPE_ERROR,
      })
    }

  }
}

const assignStatusToSpaces = (spaces, statuses, params={}) => {
  let statusMap = {}
  statuses.forEach(status => {
    statusMap[status.space] = status
  })

  spaces.forEach(space => {
    const status = statusMap[space.id]
    if (status) {
      space.free = status.free
      space.memory = status.memory
      space.expiration = status.expiration || null
    }
  })
  if ("free" in params) {
    return spaces.filter((space) => space.free === params.free)
  }
  return spaces
}

const assignLinkedInfoToUsers = (users, linkedInfo) => {
  const linkedInfoMap = {}
  if (linkedInfo) {
    linkedInfo.forEach(data => {
      linkedInfoMap[data.username] = data
    })
  }

  users.forEach(user => {
    const linkedInfo = linkedInfoMap[user.id]
    if (linkedInfo) {
      user.linked = !linkedInfo.expired && linkedInfo.linked
    }
  })

  return users
}

export const getQueryParams = (tableName, tableColumns) => {
  return (dispatch, getState) => {
    const {
      authentication: {
        userInfo: {
          username
        }
      }
    } = getState()

    const filterQueryParams = getFilterQueryParams(tableName, tableColumns, username)

    const sort = getSortQuery(getTableSort(`${tableName}`, username) || {})
    return {...filterQueryParams, ...sort}
  }
}

export const getBanners = (params) => {
  return async (dispatch) => {
    try {
      const banners = await getBannersRequest(params)
      dispatch(setBannersAction({ banners}))
      return banners
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        text: "Banners could not be retrieved",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const createBanner = (bannerData) => {
  return async (dispatch) => {
    try {
      const response = await createBannerRequest(bannerData)
      dispatch(addBannerAction({ bannerData: response }))
      return response
    } catch (error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        text: "Banner could not be created",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const updateBanner = (id, bannerData) => {
  return async (dispatch) => {
    try {
      const response = await updateBannerRequest(id, bannerData) || {}
      //Update response format as needed
      const nextBannerData = {
        ...response
      }
      dispatch(updateBannerAction({
        id,
        bannerData: nextBannerData
      }))
      return nextBannerData
    } catch(error) {
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Banner could not be updated",
        subtext: parseApiErrorMessage(error),
        type: MESSAGE_TYPE_ERROR
      })
      throw error
    }
  }
}

export const deleteBanner = (bannerId) => {
  return async (dispatch) => {
    try {
      await deleteBannerRequest(bannerId)
      dispatch(deleteBannerAction({ bannerId }))
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Delete Successful",
        subtext: "Successfully deleted banner",
        type: MESSAGE_TYPE_SUCCESS,
        timeout: 4000
      })
    } catch (error) {
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Banner could not be deleted",
        subtext: parseApiErrorMessage(error),
        type: MESSAGE_TYPE_ERROR
      })
      throw error
    }
  }
}