
import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
// import "@bit/smartworks.unity.unity-core/unity-icon"

import { navigate } from "actions/navigate"
import UnitySidebar from "components/unity/UnitySidebar/UnitySidebar"
import {
  ADMIN_BANNERS, ADMIN_SPACES, ADMIN_USERS,
  labels,
} from "constants/routes"
import { closePane } from "reduxModules/layout"
import { getPageByPathname } from "utils/routes"


const stateToProps = ({
  router: {
    location: {
      pathname
    }
  },
}) => {
  return {
    pathname,
  }
}

// to add a nav item, add a route const based obj in navList arr
// order is important
// key: the route const
// children: arr of more, top and top level only
// bottom: bool for if item should be on bottom
export const getNavList = () => {
  let navList = [
    {
      key: ADMIN_USERS,
    },
    {
      key: ADMIN_SPACES,
    },
    {
      key: ADMIN_BANNERS,
    }
  ]
  return navList
}

const bottomList = [
]

export const makeItems = (list=[]) => {
  // scale tree
  // if element has child, set children to result of makeItems
  return list.reduce((allItems, item) => {
    // if item is an array, split into key and children
    allItems.push(makeNavItem(item))
    return allItems
  }, [])
}

const makeNavItem = ({ key, children=[]}) => {
  return {
    key,
    label: labels[key],
    children: children.length ? makeItems(children) : []
  }
}

class SideNavAdmin extends Component {

  render() {
    const {
      children,
      navigate,
      closePane,
      pathname
    } = this.props

    // call makeNavItem for each item needed
    // item with children can be spread into obj and add children of makeNavItem
    const bottomItems = makeItems(bottomList)
    let navConfig = {
      top: makeItems(getNavList()),
      bottom: bottomItems
    }

    return (
      <div className="main" style={styles.mainContainer}>
        <div className="nav-container" style={styles.navWrapper}>
          <UnitySidebar
            collapsible
            customHeader={<></>}
            items={navConfig}
            onSelect={(key) => {
              closePane(key, true)
              let target = { to: key }
              navigate(target)
            }}
            selected={getPageByPathname(pathname)}
          />
        </div>
        <div className="view" style={styles.viewContainer}>
          {children}
        </div>
      </div>
    )
  }
}

export default connect(stateToProps, {
  navigate,
  closePane
})(withRouter(SideNavAdmin))

const styles = {
  mainContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    flex: 1,
    position: "relative",
    height: "100%",
    width: "100%",
    maxWidth: "100%"
  },
  navWrapper: {
    alignSelf: "stretch",
    display: "flex"
  },
  viewContainer: {
    flex: 1,
    overflowY: "auto"
  }
}
