import { Tooltip } from "antd"
import React from "react"

function UnityTooltip({
  children,
  placement="top",
  overlayInnerStyle={ //default styles for Tooltip according Altair Unity Design System
    color: "rgba(77, 77, 77, 1)",
    background: "white",
    borderRadius: "2px",
    padding: "12px",
    border: "1px rgba(231, 232, 232, 1)",
    boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.25)",
  },
  title="",
  ...props
}) {
  return (
    <Tooltip
      arrow={false}
      title={title}
      placement={placement}
      overlayInnerStyle={overlayInnerStyle}
      {...props}
    >
      {children}
    </Tooltip>
  )
}

export default UnityTooltip
