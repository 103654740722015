import {
  BranchIcon,
  BreakDownIcon,
  CalculateIcon,
  ChatIcon,
  CloudIcon,
  CodeIcon,
  Cube1Icon,
  Cube2Icon,
  CubeIcon,
  DbCRMIcon,
  DBIcon,
  DocumentIcon,
  FileArchiveIcon,
  FlagIcon,
  Gear2Icon,
  HelpIcon,
  HMCIcon,
  JoinIcon,
  LightningIcon,
  LineChartIcon,
  LoadProfileIcon,
  LockIcon,
  MagicWandIcon,
  MqttIcon,
  NotificationIcon,
  NumericFormulaIcon,
  OrderedListIcon,
  PinIcon,
  PivotIcon,
  PropertiesIcon,
  PropertyAltIcon,
  ServerIcon,
  SessionRemoteIcon,
  StepOutIcon,
  Surface3DChartIcon,
  TokenIcon,
  UploadAltIcon,
  UserAdminIcon,
  UserGroupsIcon,
  WidgetsIcon
} from "assets/icons"

// route/view consts
export const ROOT = "ROOT"
export const LOGIN = "LOGIN"
export const SIGNUP = "SIGNUP"
export const AUTHENTICATE = "AUTHENTICATE"
export const APP = "APP"
export const LINK_ALTAIR_ONE = "LINK_ALTAIR_ONE"
export const ALTAIR_ONE_REFERER_PARAM_VALUE = "marketplace"
export const ALTAIR_ONE_REFERER_PARAM_KEY = "referer"
export const PROPERTIES_HISTORY_CHART = "PROPERTIES_HISTORY_CHART"
export const USAGE_HISTORY_CHART = "USAGE_HISTORY_CHART"

export const SETTINGS = "SETTINGS"
export const DOCS = "DOCS"
export const DOCS_ANYTHING_DB = "DOCS_ANYTHING_DB"
export const DOCS_FUNCTIONS = "DOCS_FUNCTIONS"
export const DOCS_EDGE_OPS = "DOCS_EDGE_OPS"
export const DOCS_ACCESS = "DOCS_ACCESS"
export const DOCS_SPACES = "DOCS_SPACES"
export const DOCS_POLICY_RESOURCES = "DOCS_POLICY_RESOURCES"
export const DOCS_STREAMS = "DOCS_STREAMS"
export const DOCS_VIZ = "DOCS_VIZ"
export const DOCS_LABELS = "DOCS_LABELS"
export const SUPPORT = "SUPPORT"

export const NOTIFICATIONS = "NOTIFICATIONS"

export const CONTACT_US = "CONTACT_US"
export const LOGOUT = "LOGOUT"

export const SERVERLESS = "SERVERLESS"
export const FUNCTIONS = "FUNCTIONS"
export const TRIGGERS = "TRIGGERS"

export const ANYTHING_DB = "ANYTHING_DB"
export const CATEGORIES = "CATEGORIES"
export const GRAPH = "GRAPH"
export const MODELS = "MODELS"
export const EVERY_THING = "EVERY_THING"
export const OLD_EVERY_THING = "OLD_EVERY_THING"
export const CUSTOM_QUERIES = "CUSTOM_QUERIES"

export const EDGE_OPS = "EDGE_OPS"
export const ASSET_MANAGEMENT = "ASSET_MANAGEMENT"
export const CLUSTER_MANAGEMENT_OLD = "CLUSTER_MANAGEMENT_OLD"
export const CLUSTER_MANAGEMENT = "CLUSTER_MANAGEMENT"
export const CLUSTER_SOFTWARE = "CLUSTER_SOFTWARE"
export const EDGE_APPS_MANAGEMENT = "EDGE_APPS_MANAGEMENT"
export const EDGE_PROVISIONINGS = "EDGE_PROVISIONINGS"
export const RESOURCES = "RESOURCES"
export const PARAMS = "PARAMS"
export const EDGE_APPS = "EDGE_APPS"
export const CONFIG_VERSION = "CONFIG_VERSION"
export const VERSION_DEPLOYMENT = "VERSION_DEPLOYMENT"
export const ECP_IMAGES = "ECP_IMAGES"
export const ASSET_CONFIGURATIONS = "ASSET_CONFIGURATIONS"
export const DISTRIBUTIONS = "DISTRIBUTIONS"
export const ROLLOUTS = "ROLLOUTS"
export const PACKAGES = "PACKAGES"
export const PACKAGES_EDGE_APPS = "PACKAGES_EDGE_APPS"
export const PACKAGES_OBJECTS = "PACKAGES_OBJECTS"

export const STREAMS = "STREAMS"

export const DATA_VIZ = "DATA_VIZ"

export const ACCESS_CONTROL = "ACCESS_CONTROL"
export const USERS = "USERS"
export const ROLES = "ROLES"
export const APPS = "APPS"

export const LABELS = "LABELS"
export const OBJECT_STORAGE = "OBJECT_STORAGE"
export const SPACE_PICKER = "SPACE_PICKER"
export const UTILITY_BELT = "UTILITY_BELT"
export const FUNCTION_LOG = "FUNCTION_LOG"
export const SPACE_SETTINGS = "SPACE_SETTINGS"
export const SPACE_GENERAL = "SPACE_GENERAL"
export const HOME_PAGE = "HOME_PAGE"
export const HIGH_FREQ_DATA = "HIGH_FREQ_DATA"
export const VARIABLES_STORAGE = "VARIABLES_STORAGE"
export const AU_MANAGEMENT = "AU_MANAGEMENT"

export const MQTT_INSPECTOR = "MQTT_INSPECTOR"
export const HTTP_INSPECTOR = "HTTP_INSPECTOR"

export const ADMIN = "ADMIN"
export const ADMIN_USERS = "ADMIN_USERS"
export const ADMIN_SPACES = "ADMIN_SPACES"
export const ADMIN_BANNERS = "ADMIN_BANNERS"


export const DOCS_ROOT = "https://help.altair.com/altair-iot-studio"
export const SUPPORT_URL = `${DOCS_ROOT}/topics/get_started/support.htm`
export const CONTACT_US_URL = "https://altair.com/contact-us/?business_unit=BI&leadsource=Website&product_or_services=SmartWorks+IoT&applications=Internet+of+Things"

export const labels = {
  [SETTINGS]:         "Settings",
  [LOGOUT]:           "Log Out",
  [DOCS]:             "Documentation",
  [DOCS_POLICY_RESOURCES]:      "Policy Resources Documentation",
  [SUPPORT]:          "Contact Support",
  [CONTACT_US]:       "Contact Us",
  [NOTIFICATIONS]:    "Notifications",

  [ANYTHING_DB]: "AnythingDB",
  [CATEGORIES]: "Categories",
  [GRAPH]: "Graph",
  [MODELS]: "Models",
  [EVERY_THING]: "Things",
  [CUSTOM_QUERIES]: "Custom Queries",

  [EDGE_OPS]: "Edge Ops",
  [CLUSTER_MANAGEMENT]: "Asset Management",
  [ASSET_MANAGEMENT]: "Fleet Management",
  [EDGE_APPS_MANAGEMENT]: "Edge Apps Management",
  [EDGE_PROVISIONINGS]: "Edge Provisionings",
  [RESOURCES]: "Resource Catalog",
  [PARAMS]: "Parameters",
  [EDGE_APPS]: "Edge Applications",
  [PACKAGES]: "Marketplace",
  [PACKAGES_EDGE_APPS]: "Edge Applications",
  [PACKAGES_OBJECTS]: "Objects",
  [ECP_IMAGES]: "Installer Images",
  [ASSET_CONFIGURATIONS]: "Asset Configurations",
  [DISTRIBUTIONS]: "Distributions",
  [ROLLOUTS]: "Rollouts",

  [STREAMS]: "Stream Processing",
  [DATA_VIZ]: "Real Time Visualization",

  [SERVERLESS]: "User Functions",
  [FUNCTIONS]: "Functions",
  [TRIGGERS]: "Triggers",

  [ACCESS_CONTROL]: "Access Control",
  [USERS]: "Users",
  [ROLES]: "Roles",
  [APPS]: "Apps",

  [ADMIN]: "Admin Panel",
  [ADMIN_USERS]: "Users",
  [ADMIN_SPACES]: "Spaces",
  [ADMIN_BANNERS]: "Banners",

  [LABELS]: "Labels",
  [OBJECT_STORAGE]: "Object Storage",
  [SPACE_SETTINGS]: "Space Settings",
  [SPACE_GENERAL]: "General",
  [HIGH_FREQ_DATA]: "High Freq Data",
  [VARIABLES_STORAGE]: "Variables Storage",
  [AU_MANAGEMENT]: "AU Management",
  [SPACE_PICKER]: "Switch Space",
  [UTILITY_BELT]: "Utility Belt",
  [FUNCTION_LOG]: "Function Log",
  [MQTT_INSPECTOR]: "MQTT Inspector",
  [HTTP_INSPECTOR]: "API Inspector",
}

// TODO: THIS IS DEPRECATED; NEED TO MIGRATE WITH NEW ICONS WHERE THE OLD ICONS ARE USED
export const icons = {
  [SETTINGS]: "unity:gear",
  [LOGOUT]: "power-settings-new",

  [DOCS]:                 "unity:document", // book
  [DOCS_POLICY_RESOURCES]:"unity:help",
  [SUPPORT]:              "unity:chat",
  [NOTIFICATIONS]:        "unity:notification",

  [ANYTHING_DB]: "device:widgets",
  [CATEGORIES]: "unity:breakdown",
  [GRAPH]: "unity:branch",
  [MODELS]: "unity:join",
  [EVERY_THING]: "unity:ordered_list",
  [CUSTOM_QUERIES]: "unity:db_crm",


  [EDGE_OPS]: "unity:server",
  [CLUSTER_MANAGEMENT_OLD]: "unity:db",
  [CLUSTER_MANAGEMENT]: "unity:cube_1",
  [ASSET_MANAGEMENT]: "unity:db",
  [EDGE_APPS_MANAGEMENT]: "unity:heatmap_chart",
  [EDGE_PROVISIONINGS]: "unity:pivot",
  [RESOURCES]: "unity:cube",
  [EDGE_APPS]: "unity:cube_2",
  [ASSET_CONFIGURATIONS]: "settings",
  [PACKAGES]: "unity:property_alt",
  [PACKAGES_EDGE_APPS]: "unity:cube_2",
  [PACKAGES_OBJECTS]: "unity:cloud",
  [ECP_IMAGES]: "unity:file_archive",
  [DISTRIBUTIONS]: "unity:properties",
  [ROLLOUTS]: "unity:upload_alt",

  [STREAMS]: "unity:line_chart",
  [DATA_VIZ]: "unity:surface_3d_chart",

  [SERVERLESS]: "unity:numeric_formula",
  [FUNCTIONS]: "icons:code",
  [TRIGGERS]: "unity:lightning",

  [ACCESS_CONTROL]: "unity:lock",
  [USERS]: "unity:user_groups",
  [ROLES]: "unity:user_admin",
  [APPS]: "unity:magic_wand",

  [LABELS]: "unity:flag",
  [OBJECT_STORAGE]: "unity:cloud",
  [SPACE_SETTINGS]: "settings",
  [SPACE_GENERAL]: "unity:load_profile",
  [SPACE_PICKER]: "unity:step_out",
  [UTILITY_BELT]: "unity:pin",
  [FUNCTION_LOG]: "unity:ordered_list",
  [MQTT_INSPECTOR]: "unity:db_mqtt",
  [HTTP_INSPECTOR]: "unity:session_remote",
  [HIGH_FREQ_DATA]: "unity:db",
  [VARIABLES_STORAGE]: "unity:token",
  [AU_MANAGEMENT]: "unity:calculate"
}

export const newIcons = {
  [SETTINGS]: Gear2Icon,

  [DOCS]:                 DocumentIcon, // book
  [DOCS_POLICY_RESOURCES]:HelpIcon,
  [SUPPORT]:              ChatIcon,
  [NOTIFICATIONS]:        NotificationIcon,

  [ANYTHING_DB]: WidgetsIcon,
  [CATEGORIES]: BreakDownIcon,
  [GRAPH]: BranchIcon,
  [MODELS]: JoinIcon,
  [EVERY_THING]: OrderedListIcon,
  [CUSTOM_QUERIES]: DbCRMIcon,


  [EDGE_OPS]: ServerIcon,
  [CLUSTER_MANAGEMENT_OLD]: DBIcon,
  [CLUSTER_MANAGEMENT]: Cube1Icon,
  [ASSET_MANAGEMENT]: DBIcon,
  [EDGE_APPS_MANAGEMENT]: HMCIcon,
  [EDGE_PROVISIONINGS]: PivotIcon,
  [ASSET_CONFIGURATIONS]: Gear2Icon,
  [RESOURCES]: CubeIcon,
  [EDGE_APPS]: Cube2Icon,
  [PACKAGES]: PropertyAltIcon,
  [PACKAGES_EDGE_APPS]: Cube2Icon,
  [PACKAGES_OBJECTS]: CloudIcon,
  [ECP_IMAGES]: FileArchiveIcon,
  [DISTRIBUTIONS]: PropertiesIcon,
  [ROLLOUTS]: UploadAltIcon,

  [STREAMS]: LineChartIcon,
  [DATA_VIZ]: Surface3DChartIcon,

  [SERVERLESS]: NumericFormulaIcon,
  [FUNCTIONS]: CodeIcon,
  [TRIGGERS]: LightningIcon,

  [ACCESS_CONTROL]: LockIcon,
  [USERS]: UserGroupsIcon,
  [ROLES]: UserAdminIcon,
  [APPS]: MagicWandIcon,

  [LABELS]: FlagIcon,
  [OBJECT_STORAGE]: CloudIcon,
  [SPACE_SETTINGS]: Gear2Icon,
  [AU_MANAGEMENT]: CalculateIcon,
  [SPACE_GENERAL]: LoadProfileIcon,
  [SPACE_PICKER]: StepOutIcon,
  [UTILITY_BELT]: PinIcon,
  [FUNCTION_LOG]: OrderedListIcon,
  [MQTT_INSPECTOR]: MqttIcon,
  [HTTP_INSPECTOR]: SessionRemoteIcon,
  [HIGH_FREQ_DATA]: DBIcon,
  [VARIABLES_STORAGE]: TokenIcon
}

export const requestEndpoints = {
  [CLUSTER_MANAGEMENT_OLD]: "/clusters",
  [CLUSTER_SOFTWARE]: clusterId => `/clusters/${clusterId}/software/ota`,
  [RESOURCES]: "/resources",
  [PARAMS]: (resourceId, paramId) => `/resources/${resourceId}/params${paramId ? `/${paramId}` : ""}`,
  [EDGE_APPS]: "/edge-apps",
  [CONFIG_VERSION]: (edgeAppId, versionId) => `/edge-apps/${edgeAppId}/versions${versionId ? `/${versionId}` : ""}`,
  [VERSION_DEPLOYMENT]: (edgeAppId, versionId, deploymentId) => `/edge-apps/${edgeAppId}/versions/${versionId}/deployments${deploymentId ? `/${deploymentId}` : ""}`,
  [PACKAGES]: "/marketplace/edge-apps"
}
