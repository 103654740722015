import { Alert } from "antd"
import React from "react"

import {ICON_OPTIONS} from "constants/admin"
import {removeForbiddenHtmlTags} from "utils/admin"


function BannerMessage({message, background, textColor, bannerIcon}) {
  const iconOption = bannerIcon && ICON_OPTIONS.find(option => option.id === bannerIcon)?.icon
  const enhancedIcon = iconOption ? React.cloneElement(iconOption, { style: {fill: textColor} }) : iconOption
  const enhancedMessage = message ? removeForbiddenHtmlTags(message) : ""
  return (
    <Alert
      // type="error"
      showIcon={false}
      message={<div style={{color: textColor, display: "flex", alignItems: "center"}}>
        {enhancedIcon && <span style={{ marginRight: "5px" }}>{enhancedIcon}</span>}
        <span dangerouslySetInnerHTML={{ __html: enhancedMessage }} />
      </div>}
      closable
      banner
      style={{backgroundColor: background}}
    />
  )
}

export default BannerMessage

// const styles = {
//   bannerText: {
//     fontWeight: "600",
//     color: colors.white
//   },
//   bannerLink: {
//     color: "white",
//     textDecoration: "underline"
//   },
// }