import { del, get, post, put } from "./api"

export async function getAssetConfigurationRequest(id) {
  const { data } = await get(`/asset-configurations/${id}`)
  return data
}

export async function getAssetConfigurationsRequest(params) {
  const { data } = await get("/asset-configurations", params)
  return data
}

export async function createAssetConfigurationRequest(body) {
  const { data } = await post("/asset-configurations", body)
  return data
}

export async function updateAssetConfigurationRequest(body) {
  const { data } = await put(`/asset-configurations/${body.id}`, body)

  return data
}

export async function deleteAssetConfigurationRequest(id) {
  await del(`/asset-configurations/${id}`)
  return id
}

export const macAddressRegex = /^(?:[0-9A-Fa-f]{2}[:-]){5}(?:[0-9A-Fa-f]{2})$/
export const ipRegex = /^([0-9]{1,3}\.){3}[0-9]{1,3}$/
export const ipWithMaskRegex = /^([0-9]{1,3}\.){3}[0-9]{1,3}\/([0-9]|[1-2][0-9]|3[0-2])$/
