import { navigate } from "actions/navigate"
import { SEND_ANALYTICS } from "constants/config"
import { LOGIN, SPACE_PICKER } from "constants/routes"
import { getAltairOneStatusRequest, getCheckAuthenticateRequest, getUserInfoRequest, unlinkAltairOneRequest } from "utils/auth"
import { addMessage, MESSAGE_TYPE_ERROR, MESSAGE_TYPE_SUCCESS } from "utils/notifications"
import { routes } from "utils/routes"
import {
  clearLastRoute,
  getLastRoute,
  setLastRoute,
} from "utils/storage"


export const AUTHENTICATE = "AUTHENTICATE"
export const UNAUTHENTICATE = "UNAUTHENTICATE"
export const CLEAN_SPACE = "CLEAN_SPACE"
export const SET_USER_INFO = "SET_USER_INFO"
export const SET_ALTAIR_ONE_STATUS = "SET_ALTAIR_ONE_STATUS"
// const POPUP_WINDOW_FEATURES ='toolbar=no, menubar=no, width=600, height=700, top=100, left=100';

export function checkAuthentication() {
  return async (dispatch, getState) => {
    const {authentication: { isAuthenticated }, router} = getState()

    if (!isAuthenticated) {
      try {
        const response = await getCheckAuthenticateRequest()
        const { status } = response
        if (status === 200) dispatch(authenticate())
        return true
      }
      catch (error) {
        console.error(error)
        const pathname = router.location.pathname
        if(pathname !== routes[SPACE_PICKER]) setLastRoute(pathname + router.location.search)
        dispatch(failedLogin())
        return false
      }
    }
  }
}

export function authenticate() {
  return dispatch => {
    const role = getUserRole()
    dispatch(loginSuccess({role}))
  }
}

export function navigateToLastRoute() {
  return dispatch => {
    const lastRoute = getLastRoute()
    clearLastRoute()

    const nextRoute = lastRoute ?? "space-picker"
    dispatch(navigate({to: nextRoute}))
  }
}

export function getUserRole() {
  // TODO: implement
  return "root"
}

export function failedLogin() {
  // TODO: add notification error
  return dispatch => {
    dispatch(navigate({to: LOGIN}))
  }
}

export function unauthenticate() {
  return dispatch => {
    dispatch({type: UNAUTHENTICATE})
  }
}

//Called once after user successfully authenticated
//Either after logging in via the IDP, or continuing an existing session
function loginSuccess({role}) {
  return async dispatch => {
    dispatch({type: AUTHENTICATE, role})
    const { username, email } = await dispatch(getUserInfo())
    await dispatch(getAltairOneStatus())
    if(SEND_ANALYTICS === "true") {
      // eslint-disable-next-line no-undef
      FS.identify(username, { email }) // send username and email to Fullstory. ESLint disabled because function is imported from script in index.html
    }
    //Fetch user spaces here
  }
}

export function getUserInfo() {
  return async dispatch => {
    try {
      const { data: userInfo } = await getUserInfoRequest()
      dispatch({ type: SET_USER_INFO, userInfo })
      return userInfo
    }
    catch (error) {
      console.error(error)
      return {}
    }
  }
}

export const cleanSpace = () => ({
  type: CLEAN_SPACE
})

export const getAltairOneStatus = () => async dispatch => {
  try {
    const { data } = await getAltairOneStatusRequest()
    dispatch({ type: SET_ALTAIR_ONE_STATUS, altairOneStatus: data })
  }
  catch (error) {
    console.error(error)
  }
}

export const unlinkAltairOne = () => async () => {
  try {
    await unlinkAltairOneRequest()
    addMessage({
      text: "AltairOne Licenses successfully unlinked",
      type: MESSAGE_TYPE_SUCCESS
    })
  }
  catch (error) {
    addMessage({
      text: "There was an error unlinking AltairOne Licenses.",
      subtext: error.message.error,
      type: MESSAGE_TYPE_ERROR
    })
  }
}